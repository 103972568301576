import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DoNotSellService } from 'app/services/do-not-sell.service';
import { FormBuilder, Validators } from '@angular/forms';
import { oneName } from 'app/consumer/util/validators';
import { regex } from 'app/consumer/util/regex';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/services/generic/analytics.service';

@Component({
    selector: 'app-create-account-form',
    templateUrl: './do-not-sell.component.html',
    styleUrls: ['./do-not-sell.component.scss'],
    providers: [DoNotSellService]
})

export class DoNotSellComponent implements OnInit {
    submitStatus: boolean = false;
    fieldsStatus: boolean = false;
    caliChecked: boolean = false
    alertType: string;
    siteKey: string = environment.recaptcha.siteKey;
    captchaToken: string;
    @ViewChild('recaptcha') recaptchaElement: ElementRef;

    mailAddressForm = this.fb.group({
        addressLine1: ['', [Validators.minLength(2), Validators.required, Validators.pattern(regex.alphanumeric), Validators.maxLength(255)]],
        addressLine2: ['', [Validators.minLength(2), Validators.pattern(regex.alphanumeric), Validators.maxLength(255)]],
        city: ['', [Validators.minLength(2), Validators.required, Validators.pattern(/^[a-zA-Z`'\-. ]*$/), Validators.maxLength(50)]],
        state: ['', [Validators.minLength(2), Validators.required, Validators.pattern(regex.state), Validators.maxLength(2)]],
        zipCode: ['', [Validators.minLength(5), Validators.required, Validators.pattern(regex.numeric),  Validators.maxLength(5)]]
        });

    form = this.fb.group({
        firstName: ['', [Validators.minLength(1), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/), Validators.maxLength(50)]],
        lastName: ['', [Validators.minLength(1), Validators.required, oneName(), Validators.pattern(/^[a-zA-Z`'\-. ]*$/), Validators.maxLength(50)]],
        phoneNumber: ['', [Validators.required, Validators.pattern(regex.phone)]],
        emailAddress: ['', [Validators.required, Validators.pattern(regex.email), Validators.maxLength(128)]],
        mailingAddress: this.mailAddressForm
    });
    errorCode: number;

    constructor(
        private _doNotSellService: DoNotSellService,
        private fb: FormBuilder,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this._analytics.pageViewEvent("Do Not Sell");
        this.form.disable();
        this.addRecaptchaScript();
    }

    onSubmit() {
        this.dismissAlert();
        this.submitStatus = false;
        this.submitDoNotSell(); 
    }

    submitDoNotSell() {
        if (this.form.invalid || this.mailAddressForm.invalid || !this.captchaToken) {
            Object.keys(this.form.controls).forEach( control => {
                this.form.get(control).markAsTouched();
            });

            Object.keys(this.mailAddressForm.controls).forEach( control => {
                this.mailAddressForm.get(control).markAsTouched();
            });
            this.submitStatus = true;
        }else {
            this._doNotSellService.postDoNotSell(this.form.value, this.captchaToken).subscribe(
                        data => {                            
                            this.form.reset();
                            this.caliChecked = false;
                            this.alertType = 'success';
                        },
                        error => {
                            this.submitStatus = true;
                            this.alertType = 'error';
                            setTimeout(() => this.dismissAlert(), 10000);
                        }
                    );
            return;
        }
    }

    isCaliChanged(isCali: boolean) {
      this.fieldsStatus = isCali;
      this.submitStatus = isCali;

      if(isCali){
          this.form.enable();
      } else {
          this.form.disable();
      }
    }
   
    dismissAlert() {
        this.alertType = null;
    }

    addRecaptchaScript() {
        window['grecaptchaCallback'] = () => {
            this.renderReCaptcha();
          }
       
        (function(d, s, id, obj){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));
       
      }

      renderReCaptcha() {
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
          'sitekey' : this.siteKey,
          'callback': (response) => {
              this.captchaToken = response;
          }
        });
      }

    get firstName() {
        return this.form.get('firstName');
    }

    get lastName() {
        return this.form.get('lastName');
    }

    get emailAddress() {
        return this.form.get('emailAddress');
    }

    get phoneNumber() {
        return this.form.get('phoneNumber');
    }
   
    get addressLine1() {
        return this.form.get('mailingAddress.addressLine1');
    }
    
    get addressLine2() {
        return this.form.get('mailingAddress.addressLine2');
    }

    get city() {
        return this.form.get('mailingAddress.city');
    }

    get state() {
        return this.form.get('mailingAddress.state');
    }

    get zipCode() {
        return this.form.get('mailingAddress.zipCode');
    }

    get IsDisableSubmit() {
        return !this.submitStatus;
    }
}