import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class PostTokenService {

    private options = {
        headers: new HttpHeaders({
           'Content-Type': environment.headers.version,
            'Accept': environment.headers.version
        })
    }

    constructor(private http: HttpClient) { }

    postTokenCreate(token, verifyType: string) {
        const tokenObj = { token: token };

        let verifyTypeEndpoint;

        if (verifyType === 'createAccount') {
            verifyTypeEndpoint = '/email/verify/';
        } else if (verifyType === 'usernameUpdate') {
            verifyTypeEndpoint = '/change-username/claim';
        }

        return this.http.post(environment.apiConsumerBaseV2 + '/account' + verifyTypeEndpoint, tokenObj, this.options);
    }
}
