import { AfterContentInit, Component, ContentChild, ElementRef, Input } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Component({
  selector: 'rckt-input',
  templateUrl: './rckt-input.component.html',
  styleUrls: ['./rckt-input.component.scss']
})
export class RcktInputComponent implements AfterContentInit {

  private _required: boolean = false;

  @ContentChild(FormControlName) input: FormControlName;
  @ContentChild('toggleTarget') target: ElementRef;
  @Input() for: string;
  @Input() label: string;
  @Input() maskToggle: boolean = false;
  //allows "required" property to be set without adding "=true"
  @Input()
  set required(value) {
    this._required = typeof value !== 'undefined' && value !== false;
  }
  get required(): boolean {
    return this._required;
  }

  constructor() { }

  ngAfterContentInit() {
    if (this.maskToggle && !this.target) {
      console.warn('[RcktUI] No target found for input mask. Assign a target with #toggleTarget.')
    }
  }

  toggleType(masked) {
    this.target.nativeElement.type = masked ? 'password' : 'text';
  }

  get inputStatus() {
    return {
      dirty: this.input.dirty,
      disabled: this.input.disabled,
      enabled: this.input.enabled,
      invalid: this.input.invalid,
      pristine: this.input.pristine,
      touched: this.input.touched,
      untouched: this.input.untouched,
      valid: this.input.valid,
      empty: !this.input.value
    }
  }
}

@Component({
  selector: 'rckt-error',
  template: '<div class="rckt-error"><ng-content></ng-content></div>',
  styleUrls: ['./rckt-input.component.scss']
})
export class RcktInputErrorComponent {

  constructor() {}

}

@Component({
  selector: 'rckt-help-link',
  template: '<span class="rckt-help-link"><ng-content></ng-content></span>',
  styleUrls: ['./rckt-input.component.scss']
})
export class RcktHelpLinkComponent {

  constructor() {}

}
