import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class DoNotSellService {
  private url = `${environment.apiConsumerBaseV2}/privacy/do-not-sell`;

  private headers = new HttpHeaders({
    "Content-Type": environment.headers.standard,
    "Accept": environment.headers.standard
  });

  constructor(private http: HttpClient) {}

  postDoNotSell(personalInfo, captchaToken) {    
    return this.http.post(this.url, JSON.stringify(personalInfo), {
      headers: this.headers,
      params: {
        'captchaToken': captchaToken
      }
    });
  }
}