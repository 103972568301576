import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  animations: [
    trigger('modalState', [
      state('shown', style({
        opacity: 1,
        transform: 'rotateY(0) translateY(0)',
        width: 500
      })),
      transition('* => shown', [
        style({
          transform: 'rotateY(180deg) translateY(20%)',
          width: 500
        }),
        animate(300)
      ])
    ]),
    trigger('overlayState', [
      state('shown', style({
        opacity: 1
      })),
      transition('void => *', [
        style({
          opacity: 0
        }),
        animate(300)
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {

  private size;

  @Input() state = 'shown';
  @Input() set width(value: any) {
    if (typeof parseInt(value) === 'number') {
      this.size = `${value}px`;
    } else {
      this.size = value;
    }
  };

  constructor() { }

  ngOnInit() { }

  get width() {
    return this.size;
  }
}
