import { Component, OnInit } from '@angular/core';
import { LogoScriptService } from 'app/services/generic/logo-script.service';
import { SplitService } from 'app/modules/feature-flags/services/split.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'logo-block',
  templateUrl: './logo-block.component.html',
  styleUrls: ['./logo-block.component.scss']
})
export class LogoBlockComponent implements OnInit {
  rocketMoneyFlagIsActive: Boolean;
  rslFlagIsActive: Boolean;
  removeRocketSolarRocketAuto: Boolean;
  
  constructor(
    private logoScriptService: LogoScriptService,
    public splitService: SplitService
  ) {
  }

  ngOnInit() {
    this.getTreatments();
    this.logoScriptService.logoScript(window, document, "body {opacity: 0 !important}");
  }
  
  getTreatments() {
    this.splitService.client = this.splitService.init('rocketmoney');
    this.splitService.client.ready().then(() => {
      this.splitService.getTreatments();
      this.rocketMoneyFlagIsActive = this.splitService.treatments['rocket-money'] === "on";
      this.rslFlagIsActive = this.splitService.treatments['rl-solar'] === "on";
      this.removeRocketSolarRocketAuto = this.splitService.treatments['remove-rocketsolar-rocketauto'] === "on";
    })
  }
}
