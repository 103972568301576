import {Component, OnInit} from '@angular/core';
import {PostTokenService} from 'app/services/logical/post-token.service';
import {ActivatedRoute, Router} from '@angular/router';
import { CancelDeleteService } from 'app/services/logical/cancel-delete.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { readCookie } from 'app/consumer/util/cookie';
import { AnalyticsService } from 'app/services/generic/analytics.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './cancel-delete-request.component.html',
  styleUrls: ['./cancel-delete-request.component.scss'],
  providers: [PostTokenService, CancelDeleteService]
})
export class CancelDeleteRequestComponent implements OnInit {

  isValid = true;
  isComplete = false;
  redirect: string;
  cancelled = false;
  genericError = false;
  expired = false;
  notFound = false;
  errorCode: number;
  errMsg: string;
  noDeletionReq: boolean = false;
  cancelDeleteModal: boolean = false;
  alreadyCancelled: boolean = false;
  spinnerOn: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _cancelDelete: CancelDeleteService,
    private spinner: NgxSpinnerService,
    private _analytics: AnalyticsService
    ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Cancel Delete Request");
    if(sessionStorage.getItem('successSignedIn')){
      this.cancelDeleteModal = true;
    }
    else if(sessionStorage.getItem('cancelledDeleteRequest')){
      this.alreadyCancelled = true;
    }
    else{
      this._router.navigateByUrl('sign-in?from=cancelDelete');
    }
  }

  openModal() {
    this.cancelDeleteModal = true;
  }

  closeModal() {
    this.cancelDeleteModal = false;
    this._router.navigateByUrl('success-landing');
  }

  hideSpinner() {
    this.spinner.hide('loader');
    this.spinnerOn = false;
  }

  onSubmit() {
    this.spinner.show('loader', {
      fullScreen: false,
      type: 'ball-clip-rotate-multiple',
      size: 'large',
      color: '#1d1f2a',
    });
    this.spinnerOn = true;
    this.cancelDeleteModal = false;
    setTimeout(() => {this.cancelDelete()}, 3000);
  }

  cancelDelete() {
    let token = readCookie('rocket-auth');
    this._cancelDelete.cancelDeleteSubmit(token)
    .subscribe(
      data => {
          this.cancelled = true;
          sessionStorage.setItem('cancelledDeleteRequest', 'true');
      },
      error => {
          this.cancelled = false;
          this.isComplete = true;
          this.errMsg = error.message
          if(error.status === 400){
            this.noDeletionReq = true;
            setTimeout(() => this._router.navigateByUrl('success-landing'), 3000);
          }else{
            this.genericError = true;
          }
        },
      () => {
          this.isComplete = true;
          setTimeout(() => this.hideSpinner(), 3000);
          setTimeout(() => this._router.navigateByUrl('success-landing'), 3000);
        }
      );
  }
}
