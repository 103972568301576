import { Injectable } from '@angular/core';

declare let _satellite;
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    public adobeDataLayer: any;

    constructor() {
        this.adobeDataLayer = window['adobeDataLayer'] = window['adobeDataLayer'] || [];
    }

    public pageViewEvent(page: string) {
        this.adobeDataLayer.push({
            event: "PAGEVIEW",
            page: {
                pagename: page
            }
        });
    }

    public sendLoginFailureEvent(errorMessage) {
        this.adobeDataLayer.push({
            event: "LOGIN_FAILURE",
            login: {
                error: {
                    message: errorMessage
                }
            }
        });
    }

    // RAID is null here because at the time of login success, we don't have the RAID set in a token or cookie anywhere to pass in the event
    public sendLoginSuccessEvent() {
        this.adobeDataLayer.push({
            event: "LOGIN_SUCCESS",
            user: {
                raid: null
            }
        });
    }

    // standard satellite tracking passing in name of event

    public track(name: string) {
        if (typeof _satellite === 'object') {
            _satellite.track(name);
        }
    }

    // satellite tracking passing in name of event with available raid
    public trackWithID(name: string, raid: String) {
        if (typeof _satellite === 'object') {
            _satellite.track(name, { raid: raid });
        }
    }

    // decode token when request is made from ping fed to send ra id to bi

    public decodeToken(token): String {
        const payload = JSON.parse(atob(token.split('.')[1]));
        this.storeId(payload.rocketAccountId);
        return payload.rocketAccountId;
    };

    // send token in standard format ra id to bi

    public storeId(id) {
        document.cookie = 'raid=' + id;
    };
}
