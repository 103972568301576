export const regex = {
	alphanumeric: new RegExp(/^[\w #.,;:'°&()\-]*$/),
	name: new RegExp(/^[a-zA-Z_`'\-.]+(?! and )( [a-zA-Z_`'\-.]+)*$/),
	date: new RegExp(/^\d{2}[\/-]\d{2}[\/-]\d{4}$/),
	numeric: new RegExp(/^\d*$/),
	password: new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/),
	phone: new RegExp(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/),
	phoneNonUSCountryCode: new RegExp(/^\(\+\d{1,3}\) ?(\d{6,13})$/),
	phoneUSCountryCode: new RegExp(/^\+\d \(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/),
	email: new RegExp(/^[\w$\-_\.+!\*'()]+@([\w\-\_]+\.)+[a-zA-Z]+$/),
	state: new RegExp(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/),
	//regex for blacklisting "@QuickenLoans.com" and "@RocketMortgage.com" domains
	blacklistedEmail: new RegExp(/^[\w$\-_\.+!\*'()]+(@quickenloans\.com)|(@rocketmortgage\.com)$/, "i"),
	//regex to check if string is base64 encoded
	base64: new RegExp(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/)
}
