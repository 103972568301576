<div class="alert {{ type }}" [@remove]="state">
    <img src="/assets/img/icons/alert-icon-{{ type }}.svg">
    <div class="content">
        <ng-content></ng-content>
    </div>
    <svg *ngIf="isDismissable" width="21px" height="21px" viewBox="0 0 21 21" class="alertClose" (click)="close()">
        <g id="close">
            <path d="M1,1 L18,18"></path>
            <path d="M17.5,0.5 L0.5,17.5"></path>
        </g>
    </svg>

    <svg *ngIf="!isDismissable" width="21px" height="21px" viewBox="0 0 21 21" >
     
    </svg>
</div>