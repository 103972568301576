import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class RedirectService {

    constructor() { }

    private _redirect = sessionStorage.getItem('redirect');

    private _isSafe = false;

    private validURLRegex = '^(https?:\\/\\/){1}'+ // protocol
                            '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|'+ // domain name
                            '(((\\d{1,3}\\.){3}\\d{1,3}))|localhost)'+ // OR ip (v4) address
                            '(\\:\\d+)?(\\/{0,1}\\#{0,1}[-a-zA-Z\\d%_.~+]*)*'+ // port and path
                            '(\\?([a-zA-Z_]*=[\\[\\]{}:;&0-9a-zA-Z\\/d%_=.~+\\\ -?]*))?'+ // query string
                            '(\\#[-a-z\\d_]*)?$';

    private pathParams = '^\\/((#){1}[\\/a-zA-Z\\d\\-]*(\\/)*){0,1}$'; 

    private sanitize(url): string {
        this._isSafe = false;

        let decodedUrl = decodeURIComponent(url).trim();
        let successUrl = `${window.location.protocol}//${window.location.hostname + (window.location.port ? ':' + window.location.port: '')}/#/success-landing`;
       
        
        if(!(this.isValidUrl(decodedUrl))) 
            return successUrl;

        const preceedingChars: string[] = ['.','/','%2F'];
        const endingChars: string[] = ['/','%2F','?'];

        for(let domain of environment.whitelist){
            const domainStartIndex = decodedUrl.indexOf(domain);
            const domainEndIndex = domainStartIndex + domain.length;
            if(domainStartIndex >= 0) {
              if((domainStartIndex === 0 || preceedingChars.indexOf(decodedUrl.charAt(domainStartIndex-1)) > -1) &&
                  (domainEndIndex === decodedUrl.length || endingChars.indexOf(decodedUrl.charAt(domainEndIndex)) > -1)) {
                  this._isSafe = true;
                  break;
                }
            }
        }

        return this._isSafe ? decodedUrl : successUrl;
    }

    isValidUrl(urlString: string): boolean {
        try {
          let pattern = new RegExp(this.validURLRegex);
          let valid = pattern.test(urlString);
          return valid;
        } catch (TypeError) {
          return false;
        }
    }

    isValidPathParamRedirect(pathParam: string): boolean {
        try {
            let pattern = new RegExp(this.pathParams);
            let valid = pattern.test(pathParam);
            return valid;
          } catch (TypeError) {
            return false;
          } 
    }

    set redirect(url) {
        let sanitizedUrl = this.sanitize(url);
        sessionStorage.setItem('redirect', this._isSafe ? url : sanitizedUrl);
        this._redirect = this._isSafe ? url : sanitizedUrl;
    }

    get redirect() {
      return this._redirect;
    }

    submitForm(form: HTMLFormElement){
      form.submit();
    }

    changeLocationHref(newHref: string) {
      window.location.href = newHref;
    }
}
