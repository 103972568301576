<div class="mfa-column">
    <p class="text-center">
        <img src="/assets/img/logo/MagicO.svg" id="horseShoeLogo" alt="Rocket Logo">
    </p>
    <h1 class="text-center sprk-b-TypeDisplayOne">Oops! Something Went</h1>
    <h1 class="text-center sprk-b-TypeDisplayOne">Wrong On Our End</h1>

    <div class="text-center text-muted error-description">
        It's not you. It's us.
    </div>

    <div *ngIf="src?.toLowerCase()===pageSource.MFA" id="mfa-display" class="text-center text-muted">
        Don't worry. Your information's secure.
    </div>

    <div class="text-center text-muted">
        Please try again later.
    </div>
</div>