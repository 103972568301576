import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConsumerModule } from './app/consumer/consumer.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

let head = document.getElementsByTagName('head')[0];
let reference = head.childNodes[0];

/**
 * Adobe Launch script load
 */

const adobeLaunchScript = document.createElement('script');
head.insertBefore(adobeLaunchScript, reference);
adobeLaunchScript.type = 'text/javascript';
adobeLaunchScript.src = environment.adobeLaunchUrl;
adobeLaunchScript.async = true;

/**
 * Finish startup process
 */

platformBrowserDynamic().bootstrapModule(ConsumerModule);

