<single-column [talk]="false">
    <div class="text-center" *ngIf="isValid && !isComplete">
        <h1 class="sprk-b-TypeDisplayOne">We’re verifying your email address right now.</h1>
        <img src="/assets/img/Email-Unverified.svg" class="center-block" alt="Unverified Email">
    </div>
    <div class="text-center" *ngIf="isValid && !emailVerified && timeValid && isComplete">
        <ng-container [ngSwitch]="verifyType">
            <ng-container *ngSwitchCase="'createAccount'">
                <h1 class="sprk-b-TypeDisplayOne">Success! <br>Your account is all set up.</h1>
                <img src="/assets/img/Email-Success.svg" class="center-block" alt="Verified Email">
                <rckt-button type="primary" buttonStyle="block" data-analytics-click="button:Verified Email Continue" (click)="goToRedirect()" id="verify-continue">Continue</rckt-button>
            </ng-container>
            <ng-container *ngSwitchCase="'usernameUpdate'">
                <h1 class="sprk-b-TypeDisplayOne">You've successfully changed your username.</h1>
                <p>Now, let's get you signed in.</p>
                <img src="/assets/img/Email-Success.svg" class="center-block" alt="Username Changed">
                <rckt-button type="primary" buttonStyle="block" (click)="goToRedirect()" id="verify-continue">Go to Sign-In</rckt-button>
            </ng-container>
        </ng-container>
    </div>
  <div class="text-center" *ngIf="!isValid && expired && !emailResent">
    <h1 class="sprk-b-TypeDisplayOne">Looks like that verification link expired.</h1>
    <p>We'll send a new link to your email.</p>
    <rckt-button type="primary" buttonStyle="block" (click)="resendVerificationEmail()" id="send-new-link">Send A New Link</rckt-button>
  </div>
  <div class="text-center" *ngIf="!isValid && emailVerified &&!emailResent">
    <h1 class="sprk-b-TypeDisplayOne">You've already verified your email address.</h1>
    <img src="/assets/img/Email-Success.svg" class="center-block" alt="Verified Email">
    <rckt-button type="primary" buttonStyle="block" (click)="goToRedirect()" id="verify-continue">Continue to Sign-In</rckt-button>
  </div>
  <div class="text-center" *ngIf="!isValid && !timeValid && !emailResent">
    <h1 class="sprk-b-TypeDisplayOne">Something went wrong.</h1>
    <p>There was a technical issue on our end. To try again, hit the refresh button. If that doesn’t work, please <a routerLink="/talk-to-us">contact us</a>.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
  </div>
  <div class="text-center" *ngIf="(!isValid && emailVerified && !timeValid) || genericError && !emailResent">
    <h1 class="sprk-b-TypeDisplayOne">Generic Error</h1>
    <p>Uh oh! Something isn't working. Please try again or please <a routerLink="/talk-to-us">contact us</a>.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block" alt="Email Error">
  </div>
  <div class="text-center" *ngIf="emailResent">
    <h1 class="sprk-b-TypeDisplayOne">We've sent you a new verification email</h1>
    <img src="/assets/img/Email-Unverified.svg" class="center-block" alt="Unverified Email">
    <p>We’ve sent you an email from <a href="mailto:no-reply@RocketAccount.com">no-reply@RocketAccount.com</a> so we can verify your email address. Just click the button in the email to finish creating your account. If you don't receive the email, please check your spam or junk folder. </p>
    <p>Didn't get the email? <a (click)="sendEmailConfirmation()">Resend it now.</a></p>
  </div>
</single-column>
