import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rckt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() type: 'primary' | 'secondary' | 'tertiary';
  @Input() buttonStyle: 'block' | null;
  @Input() disabled;

  constructor() { }

  ngOnInit() {}

}
