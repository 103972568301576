import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { readCookie } from 'app/consumer/util/cookie';

@Injectable()
export class ValidSessionCheck implements CanActivate {
    constructor(
        private router: Router
    ) {}

    canActivate(): boolean {
        const cookieName = 'rocket-auth';
        if (readCookie(cookieName)) {
            return true;
        } else {
            this.router.navigate(['/sign-in'])
        }

        return false;
    }
}
