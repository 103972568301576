export const environment = {
    production: false,
    envName: 'test',
    domain: '.test.rocketaccount.com',
    apiConsumerBase: 'https://api.test.rocketaccount.com/api',
    apiConsumerBaseV2: 'https://api-v2.test.rocketaccount.com/api',
    apiConsumerForward: '/signinsuccess',

    partner: {
        rm: '/signinsuccess',
        rh: '/signinsuccess',
        rl: '/signinsuccess',
        rau: '/signinsuccess',
        tbi: '/signinsuccess',
        rmn: '/signinsuccess',
        rsl: '/signinsuccess'
    },

    /**
     * List of query parameters used by partners (not other rockets) in partner create-account ||
     * query is '?partners=[query]'
     */
    partnerQueryParams: [
      'amex',
      'morganstanley',
      'broker'
    ],
    partnerRedirect: "https://dashboard.test.rocketmortgage.com",
    partnerTermsAndConditions: {
      amex: "https://www.uat.rocketmortgage.com/amex-terms-and-conditions",
      ms: "https://www.rocketmortgage.com/morganstanley-terms-and-conditions"
    },
    partnerPrivacyPolicy: {
      broker: "https://info.test.rocketmortgage.com/broker/legal/privacy-policy"
    },
    partnerTermsOfUse: {
      broker: "https://info.test.rocketmortgage.com/broker/legal/terms-of-use"
    },

    federate: {
        id: 'rocket-account-web',
        adapterClientId: 'testNewLoginFlow',
        secret: 'SdCErokP8tcI03CxWthRgJjRs5BA2mPGUmgsSwxPCxKfGfOFz7qOOYgzjFNLr2a8'
    },

    whitelist: [
        'rockfin.com',
        'quickenloans.com',
        'rocketmortgage.com',
        'rockethomes.com',
        'rocketauto.com',
        'rocket.dev',
        'rockethq.com',
        'everfi-next.net',
        'rockloans.com',
        'rocketloans.com',
        'fifoundry.net',
        'foc.zone',
        'rockethq.onelink.me',
        'rocketmortgage.onelink.me',
        'truebill.xyz',
        'truebill.dev',
        'rocketmoney.dev',
        'rocketsolar.com',
        'rocketaccount.com',
        'myrocket.com',
        'rocketcard.com',
        'localhost:3000',
        'localhost:3001',
        'localhost:3002',
        'localhost:3003',
        'localhost:7001'
    ],

    headers: {
        standard: 'application/json',
        version: 'application/vnd.com.rocketaccount.account.v1+json'
    },

    adobeLaunchUrl: '//assets.adobedtm.com/b14636b10888/db3391ad7e41/launch-01cd3160fe7d-staging.min.js',

    rocketLoansMerge: {
      redirect: 'https://rocketaccount.dev.rockloans.com/ping-app#/link',
      sessionTime: 1800,
    },

    splitConfig: {
      key: '24aqkd4o18n55nrj2v1gees6m1ojkpd16hin'
    },

    recaptcha: {
      siteKey: '6LeS3WoeAAAAAJkitBDzT5cDNTaM1K4vzvwf-kbb'
    },

    lookingForTruebillLink: 'app.rocketmoney.dev/truebill-login',
    newAdminPanelUrl: 'https://ra-admin-ui.test.authrock.com/#/landing',

    auth0: {
      domain: 'auth.test.rocketaccount.com',
      clientId: 'ssnM8bsdAogvg8SUNehSGi3jP1lisEgU',
      redirectUri: 'https://dashboard.test.rocketmortgage.com?AuthType=Auth0&Auth0ConnectionType=Default',
      mfaRedirectUri: 'https://test.rocketaccount.com/#/mfa/self-service',
      connection: 'PingFed-HostedWebLogin-TEST',
      audience: 'urn:client-api:rocket-account-web-api-203352:test'
    },

    raAdminBff: "https://admin-bff.test.rocketaccount.com/api",
    raAdminRedirect: "https://ra-admin-ui.test.authrock.com"
};
