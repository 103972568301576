import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';
import { MaskComponent } from './mask/mask.component';
import { ModalComponent } from './modal/modal.component';
import { ButtonComponent } from './button/button.component';
import {
  RcktInputComponent,
  RcktInputErrorComponent,
  RcktHelpLinkComponent,
} from './rckt-input/rckt-input.component';
import { SingleColumnComponent } from './single-column/single-column.component';
import { TosComponent } from './tos/tos.component';
import { ProgressBarComponent, ProgressIndicatorComponent, ProgressStepComponent } from './progress-bar/progress-bar.component';

// Spark
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { SprkIconSetComponent } from './sprk-icon-set.utility';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SparkAngularModule
  ],
  declarations: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ProgressStepComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    RcktHelpLinkComponent,
    SingleColumnComponent,
    TosComponent,
    SprkIconSetComponent
  ],
  exports: [
    AlertComponent,
    ButtonComponent,
    MaskComponent,
    ModalComponent,
    ProgressBarComponent,
    ProgressIndicatorComponent,
    ProgressStepComponent,
    RcktInputComponent,
    RcktInputErrorComponent,
    RcktHelpLinkComponent,
    SingleColumnComponent,
    TosComponent,
    SprkIconSetComponent
  ]
})
export class UtilModule { }
