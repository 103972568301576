import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from '../../../services/generic/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './consumer.component.html',
    styleUrls: ['./consumer.component.css']
})

export class ConsumerComponent implements OnInit {

    cookies = navigator.cookieEnabled;
    sessionStorage: boolean;

    constructor() {}

    ngOnInit() {
        this.sessionStorage = this.lsTest();
    }

    lsTest() {
        const test = 'localStoreCheck';
        try {
            sessionStorage.setItem(test, test);
            sessionStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }
}
