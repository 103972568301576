<sprk-stack class="sprk-o-CenteredColumn sprk-u-phl" id="top">
    <div
      class="sprk-o-CenteredColumn sprk-u-pam sprk-o-Stack sprk-o-Stack--medium sprk-o-Stack--split@m b-Content">
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--fourth@m sprk-o-Stack--medium"
      ></div>
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--half@m sprk-o-Stack--medium"
      >
        <!-- rocket logo -->
        <img class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg" alt="Rocket Logo">
        <!-- headers and copy -->
        <div sprkStackItem id="do-not-sell-content" class="sprk-u-mbl header-title">
          <h1 sprkHeading variant="displayTwo" isPageTitle="true" class="sprk-u-mbm" >No Venda Mi</h1>
          <h1 sprkHeading variant="displayTwo" class="sprk-u-mbm">Información Personal</h1>
          <ng-container *ngIf="alertType" [ngSwitch]="alertType">
            <rckt-alert *ngSwitchCase="'success'" [type]="alertType" (closed)="dismissAlert()">
              <p>Gracias. Su solicitud ha sido procesada exitosamente.</p>
            </rckt-alert>
            <rckt-alert *ngSwitchCase="'error'" [type]="alertType" (closed)="dismissAlert()">
              <p>No pudimos procesar su solicitud debido a un error de nuestro lado. Por favor intente de nuevo más tarde.</p>
            </rckt-alert>
          </ng-container>
              
          <p class="sprk-u-mbm">
            Si es residente de California, la <b>Ley de privacidad del consumidor de California (CCPA)</b> le permite
            pedir que se deje de “vender” su información personal a terceros.
            Aunque no vendemos su información personal en el sentido tradicional, 
            compartiremos su información personal con terceros, incluyendo a nuestros afiliados. Esto podría 
            considerarse como “vender” según las leyes de privacidad de California.Complete la siguiente
             <b>información</b> que está asociada con sus interacciones con la familia de empresas de Rocket. <b>Esta 
              información nos ayudará  a acelerar y procesar adecuadamente su solicitud de dejar de vender su información. </b> <b>Esta</b> información
              la usamos solo para procesar su solicitud y no la guardamos para ningún otro fin.
          </p>
          <a href="#/privacy-policy-spanish#ca-privacy-policy">Obtenga más información sobre sus derechos como consumidor.</a>.
           <div class="cali-check-parent">
              <input type="checkbox" sprkCheckboxInput id="californiaCheck" class="cali-checkbox"
                 (change)="isCaliChanged($event.target.checked)" [(ngModel)]="caliChecked"/>
              <label for="californiaCheck" class="cali-label" sprkCheckboxLabel>
                Soy residente de California y afirmo que esta información es verdadera y exacta.
              </label>
            </div>
          <form (submit)="onSubmit()" method="post" [formGroup]="form" id="doNotSellForm" class="center-block">
           
            <div class="form-flex-box">
              <div id="first-name-parent" class="flex-item">
              <rckt-input label="Primer Nombre (obligatorio)" for="firstName">
                <input type="text" name="firstName" formControlName="firstName" class="form-control" autocomplete="firstName" id="firstName">
                <rckt-error *ngIf="firstName.errors?.required">Obligatorio.</rckt-error>
                <rckt-error *ngIf="firstName.errors?.pattern">Por favor ingrese un primer nombre que sea valido.</rckt-error>
                <rckt-error *ngIf="firstName.errors?.maxlength">No puede ser más de 50 caracteres.</rckt-error>
              </rckt-input>
              </div>
          
              <div id="last-name-parent" class="flex-item">
                <rckt-input label="Apellido (obligatorio)" for="lastName">
                  <input type="text" name="lastName" formControlName="lastName" class="form-control" autocomplete="lastName" id="lastName">
                  <rckt-error *ngIf="lastName.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="lastName.errors?.pattern">Por favor ingrese un segundo nombre que sea valido.</rckt-error>
                  <rckt-error *ngIf="lastName.errors?.maxlength">No puede ser más de 50 caracteres.</rckt-error>
                </rckt-input>
              </div>
            </div>
  
            <div class="form-flex-box">
              <div id="email-address-parent" class="flex-item">
                <rckt-input label="Dirección de Correo Electrónico (Obligatorio)" for="emailAddress">
                  <input type="text" name="emailAddress" formControlName="emailAddress" class="form-control" autocomplete="emailAddress" id="emailAddress">
                  <rckt-error *ngIf="emailAddress.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="emailAddress.errors?.pattern">Por favor ingrese un correo electrónico valido.</rckt-error>
                  <rckt-error *ngIf="emailAddress.errors?.maxlength">No puede ser más de 128 caracteres.</rckt-error>
                </rckt-input>
              </div>
  
              <div id="phone-number-parent" class="flex-item telefono-line">
                <rckt-input label="Teléfono (Obligatorio)" for="phoneNumber">
                  <input type="text" name="phoneNumber" formControlName="phoneNumber" class="form-control" autocomplete="phoneNumber" id="phoneNumber">
                  <rckt-error *ngIf="phoneNumber.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="phoneNumber.errors?.pattern">Por favor ingrese un número de teléfono valido.</rckt-error>
                </rckt-input>
              </div>
          </div>
          <div formGroupName="mailingAddress">
            <div class="form-flex-box">
              
              <rckt-input label="Dirección, Línea 1 (Obligatorio)" class="flex-item" for="addressLine1">
                <input type="text" name="addressLine1" formControlName="addressLine1" class="form-control" autocomplete="addressLine1" id="addressLine1">
                <rckt-error *ngIf="addressLine1.errors?.required">Obligatorio.</rckt-error>
                <rckt-error *ngIf="addressLine1.errors?.pattern">Por favor ingrese una dirección valida.</rckt-error>
                <rckt-error *ngIf="addressLine1.errors?.maxlength">No puede ser más de 255 caracteres.</rckt-error>
              </rckt-input>
            </div>
  
            <div class="form-flex-box">
              <rckt-input label="Dirección, Línea 2 (Opcional)" class="flex-item" for="addressLine2">
                <input type="text" name="addressLine2" formControlName="addressLine2" class="form-control" autocomplete="addressLine2" id="addressLine2">
                <rckt-error *ngIf="addressLine2.errors?.pattern">Por favor ingrese una dirección valida.</rckt-error>
                <rckt-error *ngIf="addressLine2.errors?.maxlength">No puede ser más de 255 caracteres.</rckt-error>
              </rckt-input>
            </div>
  
            <div class="form-flex-box">
              <div id="city-parent" class="flex-item">
                <rckt-input label="Ciudad (Obligatorio)" for="city">
                  <input type="text" name="city" formControlName="city" class="form-control" autocomplete="city" id="city">
                  <rckt-error *ngIf="city.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="city.errors?.pattern">Por favor ingrese una ciudad valida.</rckt-error>
                  <rckt-error *ngIf="city.errors?.minlength">No puede ser menos de 2 caracteres.</rckt-error>
                  <rckt-error *ngIf="city.errors?.maxlength">No puede ser más de 50 caracteres.</rckt-error>
                </rckt-input>
              </div>
  
              <div id="state-parent" class="flex-item">
                <rckt-input label="Estado (Obligatorio)" for="state">
                  <input type="text" name="state" formControlName="state" class="form-control" autocomplete="state" id="state">
                  <rckt-error *ngIf="state.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="state.errors?.pattern">Por favor ingrese un estado valido.</rckt-error>
                  <rckt-error *ngIf="state.errors?.minlength">Debe ser 2 caracteres.</rckt-error>
                  <rckt-error *ngIf="state.errors?.maxlength">Debe ser 2 caracteres.</rckt-error>
                </rckt-input>
              </div>
              
              <div id="zip-parent" class="flex-item">
                <rckt-input label="Código postal (Obligatorio)" for="zipCode">
                  <input type="text" name="zipCode" formControlName="zipCode" class="form-control" autocomplete="zipCode" id="zipCode">
                  <rckt-error *ngIf="zipCode.errors?.required">Obligatorio.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.pattern">Por favor ingrese un código postal valido.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.minlength">Debe ser 5 caracteres.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.maxlength">Debe ser 5 caracteres.</rckt-error>
                </rckt-input>
              </div>
            </div>
          </div>

          <div class="form-flex-box">
            <div id="recaptcha-parent" class="flex-item recaptcha">
              <div #recaptcha ></div>
            </div>
            
            <div id="do-not-sell-submit-parent" class="flex-item">
              <rckt-button type="primary" buttonStyle="block" id="do-not-sell-submit" class="do-not-sell-submit" [disabled]="IsDisableSubmit" data-analytics-click="Submit Do Not Sell">Enviar</rckt-button>
            </div>
          </div>
        </form>
        </div>
      </div>
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--fourth@m sprk-o-Stack--medium"
      ></div>
    </div>
  </sprk-stack>