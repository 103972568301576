import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-mask',
    templateUrl: './mask.component.html',
    styleUrls: ['./mask.component.scss']
})
export class MaskComponent {
    
    @Input() label: string;
    @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    isMasked: boolean = true;

    toggleMask() {
        this.isMasked = !this.isMasked;
        this.toggled.emit(this.isMasked);
    }

}
