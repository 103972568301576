<single-column [terms]="true" [talk]="true">

  <h1 class="text-center sprk-b-TypeDisplayOne"><img src="/assets/img/logo/MagicO.svg" id="horseShoeLogo" alt="Rocket Logo"></h1>
  <p class="text-center sprk-b-TypeDisplayTwo">Rocket Account</p>
  <hr>

  <div class="cookie-message alert alert-error">
    <p>Rocket Account doesn’t work with your browser.  This might be due to cookie or browser incompatibility
    issues.  Please try accessing Rocket Account with a different browser or device.</p>
  </div>
</single-column>
