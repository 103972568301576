import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'app/services/generic/analytics.service';

@Component({
  selector: 'app-privacy-policy-spanish',
  templateUrl: './privacy-policy-spanish.component.html',
  styleUrls: ['./privacy-policy-spanish.component.scss']
})
export class PrivacyPolicySpanishComponent implements OnInit {

  constructor(private _analytics: AnalyticsService) { }

  showTableOfContents = true;

  ngOnInit() {
    
    this._analytics.pageViewEvent("Privacy Policy-(Spanish)");
  }

  printPrivacyPolicy() {
    this.showTableOfContents = false;
    setTimeout(() => {
      this.print();
      this.showTableOfContents = true;
    }, 200);
  }

  print() {
    window.print();
  }

  scrollToTop(event) {
    event.preventDefault();
    scrollTo(0,0);
  }
}
