
<sprk-icon-set></sprk-icon-set>

<div class="sprk-o-CenteredColumn sprk-u-phl sprk-u-mbl" id="top">
  <div class="sprk-o-CenteredColumn sprk-o-Stack sprk-o-Stack--medium sprk-o-Stack--split@m">
    <!-- 3/5 stack w/privacy policy -->
    <div sprkStackItem class="sprk-o-Stack__item--three-fifths@m sprk-o-Stack sprk-o-Stack--medium b-Content">
      <!-- rocket logo -->
      <img class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
      <!-- headers and copy -->
      <div sprkStackItem id="rocket-privacy-policy" class="header-title">
        <h1 sprkHeading variant="displayTwo" isPageTitle="true" class="sprk-u-mbl">
          Política de privacidad del grupo de empresas Rocket
        </h1>
        <!-- print button, shows only at small viewports (under 880px) -->
        <div class="print-button--container print-button--narrow" *ngIf="showTableOfContents">
          <button sprkButton variant="secondary" id="print-button" (click)="printPrivacyPolicy()">Imprimir Politica de Privacidad</button>
        </div>
        <p><b>Fecha de vigencia</b>: 8 de enero de 2024</p>
        <p>
          Esta Política de privacidad del grupo de empresas Rocket describe cómo el grupo de empresas Rocket (como se define en  
          <a href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a> abajo) procesa la información recopilada en nuestros sitios web y 
          aplicaciones (incluyendo los dispositivos móviles) o por medio de sus interacciones en línea con nosotros (colectivamente, nuestros “Servicios”).
        </p>
        <p>
          En algunos casos, es posible que le enviemos otros avisos complementarios o de privacidad en la medida en que se apliquen a usted por los 
          productos y servicios que obtiene de nosotros o las diferentes leyes que se apliquen en su caso. 
          Es posible que algunos estados apliquen derechos de privacidad extras. Consulte la sección
          <a href="#/privacy-policy-spanish#ca-privacy-policy">Información extra sobre sus derechos de privacidad estatales para obtener más información.</a>.
        </p>
        <p>
          <b>
          Cuando usa cualquiera de nuestros servicios, reconoce las prácticas de privacidad que describimos en esta Política de privacidad del grupo de empresas Rocket. 
          También reconoce que leyó esta política y otras políticas descritas. 
          </b>
        </p>
      </div>
      <!-- "Who We Are" section -->
      <div sprkStackItem id="who-we-are">
        <h1 class="sprk-u-mbm">Quiénes somos</h1>
        <p>
          Cuando decimos "<b>el grupo de empresas Rocket</b> nos referimos a empresas que controla RKT Holdings, LLC y que comparten el nombre 
          Rocket o que están en la plataforma de Rocket. Cuando usamos Rocket nosotros o nos nos referimos a la empresa Rocket relevante, 
          en el grupo de empresas Rocket, implicada en la actividad del procesamiento. Esta política no se aplica a los afiliados de Rocket que no 
          son parte del grupo de empresas Rocket (“Afiliados”).Las prácticas sobre datos de los Afiliados se rigen por sus propias políticas de privacidad.
        </p>
        
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Grupo de empresas Rocket"
            idString="rocket-family-of-companies"
            isOpen="true"
          >
            <p>El grupo de empresas Rocket incluye:</p>
            <ul class="sprk-u-mll">
              <li>Rocket Mortgage</li>
              <li>Rocket Loans</li>
              <li>Rocket Homes</li>
              <li>Rocket Money, Inc. (conocida antes como Truebill, Inc.) (“Rocket Money”), una empresa Rocket</li>
              <li>Rocket Central</li>
              <li>ForSaleByOwner.com, una empresa Rocket</li>
              <li>Rocket Card</li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Information We Collect" section -->
      <div sprkStackItem id="information-we-collect">
        <h1 class="sprk-u-mbm">Información que recopilamos</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Información que usted da"
            idString="information-you-provide"
            isOpen="true"
          >
            <p>
              Recopilamos la información que usted nos da. Por ejemplo, recopilamos su información cuando crea una cuenta de Rocket, completa un formulario o envía 
              contenido por medio de nuestros Servicios; nos los refiere uno de nuestros colaboradores profesionales externos; hace una compra; se comunica con nosotros 
              mediante plataformas de terceros; participa en un concurso, promoción o encuesta; pide información sobre nuestros Servicios, o se comunica con 
              nosotros de cualquier otra manera. 
            </p>
            <p>
              La información específica que recopilamos depende del contexto en que usted nos la dé, y podría incluir:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">Nombre</li>
              <li class="list-item">Dirección de correo electrónico</li>
              <li class="list-item">Dirección postal</li>
              <li class="list-item">Teléfono</li>
              <li class="list-item">Número del Seguro Social</li>
              <li class="list-item">Ubicación y valor aproximado de la propiedad</li>
              <li class="list-item">Información del auto, como marca, modelo, VIN y características</li>
              <li class="list-item">Edad o fecha de nacimiento</li>
              <li class="list-item">Información demográfica, como raza, origen étnico y sexo recopilada según requisitos federales</li>
              <li class="list-item">Productos o servicios de préstamos relacionados que haya usado en el pasado</li>
              <li class="list-item">Información financiera, como ingresos, activos y patrimonio neto</li>
              <li class="list-item">Estado de la propiedad de la vivienda y estado de empleo</li>
              <li class="list-item">Información del seguro</li>
              <li class="list-item">Otros datos de perfil, incluyendo sus intereses y preferencias</li>
              <li class="list-item">Cualquier otra información que usted decida darnos</li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Información que recopilamos automáticamente"
            idString="information-we-automatically-collect"
            isOpen="true"
          >
            <p>Recopilamos automáticamente cierta información sobre sus interacciones con nosotros o nuestros Servicios, incluyendo:</p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                <p><b>Información de transacciones: </b>cuando hace una compra o completa una transacción, recopilamos información sobre la transacción, 
                  como información del producto o servicio, información económica, fecha y lugar de la transacción.
                </p>
              </li>
              <li class="list-item">
                <p><b>Información del dispositivo y del: </b>recopilamos información sobre cómo accede a nuestros Servicios, incluyendo los datos sobre el dispositivo y la red que 
                  usa, como el modelo de hardware, la versión del sistema operativo, red móvil, dirección IP, identificadores únicos del dispositivo, configuración regional y de idioma del 
                  dispositivo, tipo de navegador y versión de la aplicación. También recopilamos información sobre su actividad en nuestros Servicios, como tiempos de acceso, páginas 
                  visitadas, enlaces en los que hizo clic, productos y servicios considerados y la página que visitó antes de navegar a nuestros Servicios.
                </p>
              </li>
              <li class="list-item">
                <p><b>Su contenido: </b>según usted lo permita mediante su dispositivo o aplicación, podríamos recopilar información y contenido almacenados en su dispositivo, como fotos.
                </p>
              </li>
              <li class="list-item">
                <p><b>Información de la ubicación: </b>según los permisos de su dispositivo, podríamos recopilar información sobre el lugar exacto de su dispositivo. Usted puede detener 
                  la recopilación de información del lugar exacto en cualquier momento (para obtener más información consulte abajo la sección de
                  <a href="#/privacy-policy-spanish#your-choices">Sus opciones</a> ).
                </p>
              </li>
              <li class="list-item">
                <p><b>Información recopilada por <i>cookies</i> y tecnologías de seguimiento similares: </b> nosotros (y los que hacen trabajos para nosotros) usamos tecnologías 
                  de seguimiento, como cookies y balizas web, para recopilar información sobre sus interacciones con los Servicios.
                </p> 
                <div class="sprk-u-mll">
                  <p>
                    Las <b>cookies</b> son pequeños archivos de datos almacenados en su disco duro o en la memoria del dispositivo que nos ayudan a mejorar nuestros Servicios
                    y su experiencia, ver qué áreas y características de nuestros Servicios son populares y los correos electrónicos y anuncios que usted ve, autenticar 
                    a los usuarios, reconstruir u observar la actividad de una sesión o la de un usuario para la solución de problemas, resolver problemas, y otros propósitos, y
                    contar las visitas a nuestros sitios web.
                  </p>
                  <p>
                    Las <b>balizas web</b> (también conocidas como <b>etiquetas de píxeles</b> o <b>GIF transparentes</b>) son imágenes electrónicas que usamos en nuestros
                    Servicios y en nuestros correos electrónicos para enviar <i>cookies</i>, contar visitas y entender el uso y la eficacia de la campaña. En nuestras 
                    aplicaciones móviles, también podríamos usar tecnologías que no están basadas en el navegador como las cookies.
                  </p>
                  <p>
                    Por ejemplo, nuestras aplicaciones pueden incluir <b>kits de desarrollo de software</b> (conocidos como SDK), que son un código que envía a un servidor 
                    información sobre el uso que usted les da. Estos SDK nos permiten dar seguimiento a nuestras conversiones, mostrarle publicidad dentro y fuera del 
                    Servicio y darle otras funciones.
                    <b>Para obtener más información sobre estas tecnologías de seguimiento y cómo deshabilitarlas, 
                      consulte la sección <a href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a> abajo.</b>
                  </p>
                </div>
              </li>
            </ul>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Información que recopilamos de otras fuentes"
            idString="information-we-collect"
            isOpen="true"
          >
            <p>
              Obtenemos información de fuentes externas en ciertas situaciones. Por ejemplo, podríamos recopilar información sobre usted de servicios de verificación 
              de identidad u otros servicios de verificación, agencias de crédito (incluyendo su informe de crédito), redes de publicidad, proveedores de listas de
              distribución de correo, proveedores de servicios y corredores de datos. Nuestros Servicios también podrían permitirle o pedirle que integre o conecte su 
              cuenta Rocket con servicios de terceros por medio de proveedores de servicios (como Plaid).
            </p>
            <p>
              La información que recibimos de esos terceros varía dependiendo de la información que esas entidades faciliten. Algunos ejemplos
              de los tipos de información que recibimos incluyen información de cuentas financieras, de saldos de las cuentas, de cuentas de crédito, 
              de cuentas de préstamos, de cuentas de inversión, identificadores e información sobre propietarios de cuentas, de transacciones de cuentas e información laboral.
            </p>
            <p>
              Además, si usted crea o inicia sesión en su cuenta Rocket usando una plataforma de terceros, como Facebook o Google, tendremos acceso a cierta 
              información suya en esa plataforma, como su nombre, fecha de nacimiento y foto de perfil, de acuerdo con los procedimientos de autorización de la plataforma. 
            </p>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Información que deducimos"
            idString="information-we-derive"
            isOpen="true"
          >
            <p>
              Podemos deducir información o hacer inferencias sobre usted según la información que recopilamos. Por ejemplo, podemos hacer inferencias
              sobre su ubicación según su dirección IP, ciudad o código postal, o que está buscando comprar ciertos productos basándonos en la 
              información que nos da, su historial de navegación y las compras que haya hecho. 
            </p>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Plaid Technologies"
            idString="plaid-technologies"
            isOpen="true"
          >
            <p>
              Rocket le permite usar Plaid Technologies, Inc. para recopilar datos de instituciones financieras. Cuando usa los Servicios, usted reconoce 
              y acepta que la información se procesará de acuerdo con la
              <a href="https://plaid.com/legal/#consumers" target="blank">Política de privacidad de Plaid,</a> y usted les da a Rocket y a Plaid los
              mismos derechos, facultades y autoridad que se especifican en esa política.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "How We Use Your Information" section -->
      <div sprkStackItem id="how-we-use">
        <h1 class="sprk-u-mbm">Cómo usamos su información</h1>
        <p>Usamos la información que recopilamos para prestarle nuestros Servicios.</p>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Otros motivos para recopilar información"
              idString="other-reasons-to-collect"
              isOpen="true"
            >
            <p>
              También la usamos para:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                Cumplir o prestar los servicios que usted haya pedido
              </li>
              <li class="list-item">
                Ofrecer, mantener, mejorar y crear nuevos productos, servicios y características, incluyendo la depuración y reparación de errores en nuestros
                Servicios
              </li>
              <li class="list-item">
                Personalizar su experiencia con nosotros
              </li>
              <li class="list-item">
                Enviarle avisos técnicos, alertas de seguridad, mensajes de soporte y otros mensajes sobre las transacciones o la relación comercial, incluyendo
                respuestas a sus consultas y comentarios
              </li>
              <li class="list-item">
                Comunicarnos con usted sobre productos, servicios y eventos que ofrecen Rocket y otros, y enviarle noticias e información que creamos que 
                le interesarían. <b> <a href="#/privacy-policy-spanish#your-choices">Para</a> obtener información sobre cómo 
                dejar de recibir estas comunicaciones en cualquier momento consulte abajo la sección Sus opciones.</b>
              </li>
              <li class="list-item">
                Monitorear y analizar tendencias, uso y actividades relacionadas con nuestros productos y servicios
              </li>
              <li class="list-item">
                Personalizar los anuncios que ve en plataformas y sitios web de terceros. <b>Para obtener más información, consulte abajo la sección 
                <a href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis. </a></b>
              </li>
              <li class="list-item">
                Mostrarle ofertas de servicios de terceros personalizados para usted, según la información suya que hayamos recopilado
              </li>
              <li class="list-item">
                Facilitar concursos, sorteos y promociones, y procesar y entregar entradas y premios
              </li>
              <li class="list-item">
                Detectar, investigar y ayudar a prevenir incidentes de seguridad y otras actividades maliciosas, engañosas, fraudulentas o 
                ilegales, y ayudar a proteger los derechos y la propiedad de Rocket y de otros
              </li>
              <li class="list-item">
                Cumplir nuestras obligaciones legales y financieras
              </li>
              <li class="list-item">
                Crear información sin identificación, anónima o agrupada
              </li>
              <li class="list-item">
                Cumplir cualquier otro propósito bajo su dirección.
              </li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "How We Share Your Information" section -->
      <div sprkStackItem id="how-we-share">
        <h1 class="sprk-u-mbm">Cómo compartimos su información</h1>
        <p>
          Compartimos información personal en las siguientes circunstancias o como se describe en esta política.
        </p>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Cómo la compartimos entre el grupo de empresas Rocket"
              idString="sharing-among-rocket-family-of-companies"
              isOpen="true"
            >
            <p>
              <b>El grupo de empresas Rocket</b> usa y comparte su información internamente según esta 
              <b>política de privacidad del grupo de empresas Rocket</b>. Esto le permite <b>al grupo de empresas Rocket</b> administrar, prestar y
              ofrecerle varios de nuestros servicios durante el amplio ciclo de vida de sus necesidades personales y financieras.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Cómo la compartimos con Afiliados"
              idString="sharing-with-affiliates"
              isOpen="true"
            >
            <p>
              Rocket también comparte su información con nuestros Afiliados. Por ejemplo, compartimos, o podemos compartir, su información:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                Con Afiliados que actúan como proveedores, prestadores de servicios y consultores que acceden a información personal para prestar servicios por nosotros, como
                las empresas que nos ayudan con los servicios de call center/directorio
              </li>
              <li class="list-item">
                Con Afiliados para sus propios servicios y marketing
              </li>
              <li class="list-item">
                Con Afiliados para fines de informes para agencias reguladoras y registro de valores medibles del rendimiento
              </li>
              <li class="list-item">
                Con Afiliados si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, (2) que usted ha infringido
                la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, el público u otros
              </li>
              <li class="list-item">
                En relación con, o durante las negociaciones relacionadas a cualquier fusión, venta de activos de la empresa, financiamiento o 
                adquisición de todo o de una parte de nuestro negocio por otra empresa.
              </li>
              <li class="list-item">
                Con Afiliados con su consentimiento o por instrucciones suyas
              </li>
            </ul>
            <p>
              También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Cómo la compartimos con terceros"
              idString="sharing-with-other-third-parties"
              isOpen="true"
            >
            <p>
              Rocket comparte su información con terceros no afiliados en las siguientes circunstancias:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                Compartimos información personal con proveedores de productos y servicios y con consultores que acceden a información personal 
                para prestarnos servicios, como empresas que nos ayudan con alojamiento web, envío y entrega, procesamiento
                de pagos, prevención del fraude, servicio de atención al cliente, análisis de datos, títulos de propiedad y servicios de tasación, soporte técnico, mercadeo y publicidad.
              </li>
              <li class="list-item">
                Si usted envía una reseña de algún producto o publica contenido en otra área pública de nuestros Servicios, compartimos esa información 
                públicamente en nuestros Servicios.
              </li>
              <li class="list-item">
                Si usted elige usar las integraciones que ofrecemos en nuestros Servicios, como compartir su ubicación por medio de nuestra integración con Google 
                Maps, podríamos compartir cierta información con los colaboradores de la integración. 
              </li>
              <li class="list-item">
                Compartimos información personal con terceros para los propios servicios de ellos y para fines de mercadeo, análisis y, en algunos casos, para comercializar
                y ofrecer productos y servicios junto con terceros.
              </li>
              <li class="list-item">
                Cuando usted pide información sobre los servicios que ofrecen otras empresas, podríamos revelar información sobre usted a esas empresas para que nosotros o
                ellas podamos enviarle avisos y determinar si usted califica para recibir productos o servicios.
              </li>
              <li class="list-item">
                Podríamos revelar información personal si creemos que la revelación se hace según cualquier ley o proceso legal aplicable, o si así lo 
                exigen las leyes o procesos legales, incluyendo solicitudes legales de las autoridades para cumplir los requisitos de seguridad nacional o de aplicación de la ley.
              </li>
              <li class="list-item">
                Podríamos compartir información personal si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, 
                (2) que usted ha infringido la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, 
                el público u otros.
              </li>
              <li class="list-item">
                Compartimos información personal con nuestros abogados y otros asesores profesionales cuando es necesario para recibir asesoría o 
                proteger y administrar nuestros intereses comerciales.
              </li>
              <li class="list-item">
                Podríamos compartir información personal en relación con, o durante las negociaciones relacionadas con cualquier fusión, venta de activos 
                de la empresa, financiamiento o adquisición de todo o de una parte de nuestro negocio por otra empresa.
              </li>
              <li class="list-item">
                Compartimos información personal con su consentimiento o por instrucciones suyas incluyendo, entre otros, 
                mediante integraciones de terceros que usted elija habilitar.
              </li>
              <li class="list-item">
                También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
              </li>
              <li class="list-item">
                Si usted se suscribió a un programa de ofertas de códigos cortos de SMS, sus datos de suscripción y su consentimiento para el programa de 
                ofertas no se compartirá con terceros. Si dio su consentimiento por separado para que lo contacten (fuera del programa de ofertas de 
                códigos cortos de SMS), esa información se puede compartir. 
              </li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Advertising and Analytics" section -->
      <div sprkStackItem id="advertising-and-analytics">
        <h1 class="sprk-u-mbm">Publicidad y análisis</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Web y aplicaciones móviles"
              idString="web-and-mobile-apps"
              isOpen="true"
            >
            <p>
              Compartimos información personal para permitir que otros presten servicios de análisis y publiquen anuncios en la web y en aplicaciones 
              móviles. Estas entidades pueden usar <b>cookies, balizas web, identificadores de dispositivos</b> y otras tecnologías para recopilar 
              información sobre cómo usa usted nuestros Servicios y otros sitios web y aplicaciones, incluyendo su dirección IP, navegador web, información 
              de redes móviles, páginas vistas, tiempo en las páginas o aplicaciones móviles, enlaces en los que hizo clic e información de conversión.
            </p>
            <p>
              Esta información la pueden usar Rocket y otros para, entre otras cosas, analizar y rastrear datos, reconstruir u observar la actividad 
              relacionada con una sesión, determinar la popularidad de cierto contenido, mostrar publicidad y contenido según sus intereses en 
              nuestros Servicios y otros sitios web, y comprender mejor su actividad en línea.
            </p>
            <p>
              <b>
                Para obtener más información sobre los anuncios según sus intereses, o para que se deje de usar su información de navegación web con fines 
                de publicidad conductual, visite <a href="http://www.aboutads.info/choices" target="blank">www.aboutads.info/choices</a>. 
              </b>
              Es posible que su dispositivo también incluya una función (<i>“Limit Ad Tracking”</i> [Limitar el seguimiento de anuncios] en iOS, u 
              <i>“Opt Out of Interest-Based Ads”</i> [Dejar de recibir anuncios según mis intereses] u <i>“Opt Out of Ads Personalization”</i> 
              [Dejar de personalizar mis anuncios] en Android) que le permite evitar que se recopile cierta información mediante aplicaciones móviles y que se usa 
              con fines de publicidad conductual. Las leyes de privacidad de algunos estados también les dan a sus residentes derechos relacionados a la exclusión 
              voluntaria directa del intercambio de información personal con fines de publicidad basada en el contexto o en intereses. 
              Para obtener más información, consulte abajo la sección Otra información sobre sus derechos de privacidad estatales.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Terceros"
              idString="third-parties"
              isOpen="true"
            >
            <p>
              También compartimos información personal para trabajar con terceros para mostrarle anuncios como parte de campañas personalizadas en plataformas 
              de terceros (como Facebook). Como parte de esas campañas publicitarias, nosotros o las plataformas de terceros podríamos convertir información sobre usted, como 
              la dirección de correo electrónico o el teléfono, en un valor único que se puede combinar con una cuenta de usuario en esas plataformas para que podamos conocer 
              sus intereses y ofrecerle publicidad personalizada según sus intereses. Tenga en cuenta que las plataformas de terceros pueden darle la opción de ver o no este 
              tipo de anuncios personalizados.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Children's Privacy" section -->
      <div sprkStackItem id="childrens-privacy">
        <h1 class="sprk-u-mbm">Privacidad de los niños</h1>
        <p>
          Los productos Rocket no están destinados para niños. No dirigimos nuestros Servicios a niños menores de 16 años, ni intentamos pedir ni recibir de manera intencional 
          información de niños.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "California Shine the Light" section -->
      <div sprkStackItem id="california-shine-the-light">
        <h1 class="sprk-u-mbm">Ley Shine The Light de California</h1>
        <p>
          La ley de California les permite a los residentes de California pedir cierta información sobre cómo se comparten sus datos con terceros con fines 
          de marketing directo. Si es residente de California y quiere pedir esa información, llámenos al <a href="tel:888-444-7492">888-444-7492</a>.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Processing of Information" section -->
      <div sprkStackItem id="processing-of-information">
        <h1 class="sprk-u-mbm">Procesamiento de la información en los Estados Unidos y otros países</h1>
        <p>
          Rocket tiene su sede en los Estados Unidos y el grupo de empresas Rocket ofrece bienes y servicios a consumidores en los Estados Unidos.
          Tenemos operaciones y proveedores en los Estados Unidos y otros países. Por lo tanto, nosotros y los que prestan servicios por nosotros, podemos 
          transferir, almacenar o acceder a su información personal en jurisdicciones que podrían no tener niveles de protección de datos que sean equivalentes 
          a los de la jurisdicción donde vive.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Your Choices" section -->
      <div sprkStackItem id="your-choices">
        <h1 class="sprk-u-mbm">Sus opciones</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Derecho a corregir la información de la cuenta"
              idString="account-information"
              isOpen="true"
            >
            <p>
              Usted puede actualizar y corregir cierta información de la cuenta, o eliminar la cuenta, iniciando sesión o llamándonos al
              <a href="tel:888-444-7492">888-444-7492</a>. Tenga en cuenta que podríamos retener cierta información según lo exija la 
              ley o para nuestros fines comerciales legítimos. Para obtener más información, consulte la sección Otra información sobre 
              sus derechos de privacidad estatales.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Información de la ubicación"
              idString="location-information"
              isOpen="true"
            >
            <p>
              Cuando inicie por primera vez cualquiera de nuestras aplicaciones móviles que recopilan información de ubicación exacta, o cuando visite 
              partes de nuestros Servicios que recopilan información de ubicación exacta,se le pedirá que dé su consentimiento para que la aplicación 
              recopile esta información. Si inicialmente autoriza que recopilemos la información de la ubicación, después puede detener la recopilación de esa 
              información en cualquier momento cambiando las preferencias en su dispositivo móvil. Es importante mencionar que es posible que algunos Servicios 
              no funcionen sin la información de ubicación exacta. También puede bloquear una aplicación móvil para que ya no obtenga información de ubicación 
              siguiendo el proceso de desinstalación estándar para eliminar la aplicación de su dispositivo. 
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Señales para evitar el rastreo"
              idString="do-not-track-signals"
              isOpen="true"
            >
            <p>
              Es posible que esté usando un navegador de Internet que pueda comunicar sus preferencias de privacidad al sitio web, incluyendo solicitudes de no 
              rastrear su historial de uso y navegación. Nuestros sitios web están configurados para reconocer esas señales. Si reconocemos una de estas señales —que 
              su navegador puede denominar o etiquetar como señales de “no rastrear” o “preferencia de exclusión voluntaria” o “preferencia global”—, le damos la opción 
              de enviar una solicitud de exclusión voluntaria de la venta/compartición. Para obtener más información sobre excluirse de la venta/compartir información 
              personal, consulte abajo la sección Otra información sobre sus derechos de privacidad estatales.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "Changes To This Policy" section -->
      <div sprkStackItem id="changes-to-this-policy">
        <h1 class="sprk-u-mbm">Cambios a esta política</h1>
        <p>
          Podemos cambiar esta <b>política de privacidad del grupo de empresas Rocket</b> cada cierto tiempo. Si hacemos cambios, se lo avisaremos 
          cambiando la fecha de la parte superior de esta política y, en algunos casos, podríamos decírselo de otra manera, como publicando una 
          declaración en nuestro sitio web o enviándole un aviso. Le recomendamos que revise esta política con regularidad para mantenerse informado 
          sobre nuestras prácticas de información y las opciones que tiene disponibles.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <div sprkStackItem id="contact-information">
        <h1 class="sprk-u-mbm">Información de contacto</h1>
        <p>
          Si tiene preguntas sobre esta <b>Política de privacidad del grupo de empresas Rocket</b>, nuestras prácticas de información u otros temas 
          de privacidad relacionados con nuestros Servicios, llámenos al <a href="tel:888-444-7492">888-444-7492</a>.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
      <!-- "California Privacy Policy" section -->
      <div sprkStackItem id="ca-privacy-policy">
        <h1 class="sprk-u-mbm">OTRA INFORMACIÓN SOBRE SUS DERECHOS DE PRIVACIDAD ESTATALES </h1>
        <p>
          Ciertos estados, exigen que demos más información sobre nuestras prácticas de privacidad y sus derechos de privacidad. Los 
          estados , que han aprobado leyes de privacidad amplias, que les dan derechos de privacidad específicos a los residentes, 
          incluyen: California, Colorado, Connecticut, Delaware, Indiana, Iowa, Montana, Oregón, Tennessee, Texas, Utah y Virginia.  
          Abajo le damos más avisos sobre nuestras prácticas de privacidad y los derechos del consumidor relacionados con su información. 
          Algunos derechos pueden depender de la fecha de vigencia de la ley y de si usted es residente de un estado con leyes de privacidad 
          específicas, y de otros factores. Además, algunas de las empresas del grupo de empresas Rocket son instituciones financieras sujetas 
          a otros marcos reglamentarios de privacidad como la Ley Gramm Leach Bliley (GLBA), y es posible que no se apliquen las revelaciones de 
          privacidad ni los derechos descritos en esta sección. 
        </p>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Aviso de información recopilada"
              idString="collection-and-use-of-personal-information"
              isOpen="true"
            >
            <p>
              En los 12 meses anteriores, hemos recopilado las siguientes categorías de información personal:
            </p>
            <sprk-table idString="collection-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Categoría</th>
                  <th>Ejemplos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identificadores</td>
                  <td>
                    Nombre, alias, dirección postal, identificador en línea (incluyendo dirección IP), dirección de correo electrónico, número de Seguro 
                    Social, número de licencia de conducir u otros identificadores similares.
                  </td>
                </tr>
                <tr>
                  <td>Registros de clientes</td>
                  <td>
                    Nombre del empleador, historial de empleo, número de cuenta bancaria, número de tarjeta de crédito, número de tarjeta de débito y otra 
                    información financiera (incluyendo informes de crédito, salario y patrimonio neto).
                  </td>
                </tr>
                <tr>
                  <td>Características de las clasificaciones protegidas bajo la ley federal o estatal</td>
                  <td>Edad, sexo, estado militar y estado civil.</td>
                </tr>
                <tr>
                  <td>Información comercial</td>
                  <td>Registros de propiedades, productos o servicios comprados.</td>
                </tr>
                <tr>
                  <td>Actividad en Internet u otra red similar</td>
                  <td>
                    Historial de navegación, historial de búsqueda, información de la interacción de un consumidor con un sitio web, 
                    aplicación o anuncio.
                  </td>
                </tr>
                <tr>
                  <td>Información de geolocalización</td>
                  <td>Lugar o movimientos físicos.</td>
                </tr>
                <tr>
                  <td>Información sensorial</td>
                  <td>Audio (como grabaciones de llamadas) o el contenido de los videos que elija cargar.</td>
                </tr>
                <tr>
                  <td>Información profesional o del empleo </td>
                  <td>Empleador actual o lestado de empleo.</td>
                </tr>
                <tr>
                  <td>Inferencias extraídas de otra información personal</td>
                  <td>Preferencias, características y ubicación aproximada.</td>
                </tr>
                <tr>
                  <td>Información personal sensible</td>
                  <td>
                    Número del Seguro Social, licencia de conducir, tarjeta de identificación estatal o número de pasaporte; inicio de sesión de 
                    cuenta, número de cuenta financiera, de tarjeta de débito o de tarjeta de crédito en combinación con cualquier código de acceso o 
                    de seguridad exigido, contraseña o credenciales que permitan el acceso a una cuenta; geolocalización precisa; origen racial o 
                    étnico, creencias religiosas o filosóficas, o afiliación sindical; contenido de un correo del consumidor, correo electrónico y 
                    mensajes de texto, a menos que la empresa sea el destinatario previsto de la comunicación. 
                  </td>
                </tr>
              </tbody>
            </sprk-table>
            <p>
              <b>Para obtener más información sobre los puntos de datos precisos que recopilamos y las categorías de fuentes de dicha recopilación, consulte la sección
              <a href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>.</b> Recopilamos información personal para los fines comerciales 
              descritos en la sección <a href="#/privacy-policy-spanish#how-we-use">Cómo usamos la información</a>.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Revelación de la información personal"
              idString="disclosure-of-personal-information"
              isOpen="true"
            >
            <p>
              En los 12 meses anteriores, hemos revelado las siguientes categorías de información personal con fines comerciales a las siguientes 
              categorías de destinatarios:
            </p>
            <sprk-table idString="disclosure-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Categoría de la información personal</th>
                  <th>Categorías de los destinatarios</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identificadores</td>
                  <td>
                    Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores 
                    de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento 
                    de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas).
                  </td>
                </tr>
                <tr>
                  <td>Registros de clientes</td>
                  <td>
                    Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores
                    de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento
                    de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas).
                  </td>
                </tr>
                <tr>
                  <td>Características de clasificaciones protegidas</td>
                  <td>Proveedores de servicios, afiliados, socios hipotecarios y empresas asociadas.</td>
                </tr>
                <tr>
                  <td>Información comercial</td>
                  <td>
                    Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores 
                    de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento 
                    de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas).
                  </td>
                </tr>
                <tr>
                  <td>Actividad en Internet u otra red</td>
                  <td>
                    Redes publicitarias y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, de almacenamiento
                    de datos, y sistemas operativos y plataformas).
                  </td>
                </tr>
                <tr>
                  <td>Información de geolocalización</td>
                  <td>Socios y proveedores de servicios.</td>
                </tr>
                <tr>
                  <td>Información sensorial</td>
                  <td>Socios.</td>
                </tr>
                <tr>
                  <td>Información profesional o del empleo</td>
                  <td>Socios y proveedores de servicios.</td>
                </tr>
                <tr>
                  <td>Inferencias extraídas de otra información personal</td>
                  <td>
                    Socios, redes de publicidad y proveedores de servicios (como proveedores de servicios de Internet, de análisis de datos, 
                    de servicios de correo electrónico, de almacenamiento de datos, de atención al cliente, de administración de cuentas, y 
                    sistemas operativos y plataformas).
                  </td>
                </tr>
                <tr>
                  <td>Información personal sensible</td>
                  <td>
                    Socios (como socios hipotecarios o concesionarios de autos, dependiendo de los Servicios), redes de publicidad y proveedores 
                    de servicios (como proveedores de servicios de Internet, de análisis de datos, de servicios de correo electrónico, de almacenamiento 
                    de datos, de atención al cliente, de administración de cuentas, y sistemas operativos y plataformas), afiliados y empresas asociadas. 
                  </td>
                </tr>
              </tbody>
            </sprk-table>
          </sprk-accordion-item>
        </sprk-accordion>

        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Derecho a limitar el procesamiento de información personal sensible"
              idString="right-to-limit-processing-of-sensitive-personal-information"
              isOpen="true"
            >
            <p>
              En California, usted tiene derecho a limitar nuestro procesamiento de su información personal sensible solo a los usos que sean necesarios 
              para prestar los servicios o dar los bienes que razonablemente espera un consumidor promedio que pide dichos bienes o servicios. Cuando 
              recopilamos su información personal sensible, la recopilamos y usamos para prestar los servicios o dar bienes para los 
              que es necesaria y según lo que usted espera razonablemente.
            </p>
            <p>
              Según otras leyes de privacidad estatales, solo se nos permite recopilar ciertos elementos de información personal sensible después de haber 
              obtenido su consentimiento para hacerlo. Cuando sea necesario, obtendremos su consentimiento para recopilar y usar información personal sensible.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>

        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Venta de la información personal"
              idString="sale-of-personal-information"
              isOpen="true"
            >
            <p>
              Las leyes de privacidad estatales exigen ciertas revelaciones para las empresas que “venden” información 
              personal según la ley de privacidad del estado correspondiente. Cada estado define la “venta” de datos de manera diferente. En California, 
              la “venta” de datos significa ciertas circunstancias en las que Rocket ha compartido información personal con terceros o Afiliados a 
              cambio de una contraprestación valiosa. Otros estados definen la “venta” de datos como el intercambio de 
              información personal de Rocket por un valor monetario con un tercero no afiliado. 
            </p>
            <p>
              Según esta definición, no “vendemos” su información personal.
            </p>
            <p>
              Según la definición de California, “vendemos” información personal para permitir que nuestros Afiliados y ciertos terceros usen su 
              información según sus políticas de privacidad. “Vendemos” las siguientes categorías de información personal a las siguientes categorías 
              de terceros: 
            </p>
            <sprk-table idString="sale-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Categoría de la información personal</th>
                  <th>Categorías de terceros</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identificadores</td>
                  <td>Terceros afiliados y no afiliados.</td>
                </tr>
                <tr>
                  <td>Categorías de información personal descrita en la subdivisión (e) de la sección 1798.80</td>
                  <td>Terceros afiliados y no afiliados.</td>
                </tr>
                <tr>
                  <td>Información comercial</td>
                  <td>Terceros afiliados y no afiliados.</td>
                </tr>
                <tr>
                  <td>Actividad en Internet u otra red</td>
                  <td>Terceros afiliados y no afiliados.</td>
                </tr>
                <tr>
                  <td>Inferencias extraídas de otra información personal</td>
                  <td>Terceros afiliados y no afiliados.</td>
                </tr>
              </tbody>
            </sprk-table>
          </sprk-accordion-item>
          <sprk-accordion class="accordion-header">
            <sprk-accordion-item
            heading="Derecho a no participar en la venta"
            idString="consumer-rights"
            isOpen="true"
            >
              <p>
                Usted tiene el derecho de dejar de participar en la “venta” descrita arriba, en cualquier momento, visitando nuestra página web 
                <a href="#/es/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
                No vendemos ni compartimos de manera intencional información personal de consumidores menores de 16 años. 
              </p>
            </sprk-accordion-item>
          </sprk-accordion>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Derecho a no participar en el intercambio de información que se comparte o usa en la publicidad dirigida"
              idString="right-to-opt-out-of-personal-information"
              isOpen="true"
            >
            <p>
              California exige que le informemos si “compartimos” su información personal con terceros. En California, “compartir” significa que ponemos 
              a disponibilidad o revelamos su información a terceros para publicidad conductual de contexto cruzado. Además “publicidad conductual de 
              contexto cruzado” significa la publicidad dirigida a usted basándose en la información personal obtenida de su actividad en negocios, sitios 
              web, aplicaciones y servicios distintos de nuestros Servicios. 
            </p>
            <p>
              De manera similar, las leyes de privacidad de otros estados nos exigen que informemos si usamos su información para 
              publicidad dirigida. Publicidad dirigida significa mostrarle anuncios seleccionados basándonos en la información personal obtenida de sus 
              actividades con el tiempo y en sitios web no afiliados o aplicaciones en línea para predecir sus preferencias o intereses. 
            </p>
            <p>
              En consecuencia, “compartimos” su información personal con terceros con fines de publicidad contextual cruzada, y la usamos para publicidad dirigida. 
              Específicamente, compartimos sus identificadores y su actividad en Internet u otras redes con nuestros colaboradores publicitarios. 
            </p>
            <p>
              <b>Usted tiene derecho a optar por no “compartir” ni recibir publicidad dirigida accediendo a nuestro sitio web 
              <a href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
              También respetamos las señales de “No rastrear” del navegador, como mencionamos arriba.</b>
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Retención de la información personal"
              idString="retention-of-personal-information"
              isOpen="true"
            >
            <p>
              No retendremos su información personal por más tiempo del razonablemente necesario para cumplir los propósitos que revelamos en esta política. 
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Derechos de información del consumidor a nivel estatal"
              idString="state-consumer-data-rights"
              isOpen="true"
            >
            <p>
              Sujeto a ciertas limitaciones, usted tiene derecho a (1) pedir más información sobre las categorías y piezas específicas de información personal 
              que recopilamos, usamos, revelamos y vendemos, y para acceder a su información, (2) pedir que se elimine su información personal, (3) pedir 
              la corrección de su información personal y (4) no ser discriminado por ejercer estos derechos. 
            </p>
            <p>
              <b>Usted puede hacer estas solicitudes 
                <a href="https://www.rocketmortgage.com/privacy-rights/data-request?qls=QRD_12345678.0123456789" target="blank">
                  presentando una solicitud en línea</a> o llamando al <a href="tel:888-444-7492">888-444-7492</a>.
              </b> 
              Verificaremos su solicitud pidiéndole que nos dé información relacionada con sus interacciones recientes con nosotros.
            </p>
            <p>
              Si recibimos su solicitud de un agente autorizado, podemos pedirle evidencia de que usted le dio a ese agente un poder, o que el agente 
              tiene autoridad válida por escrito para presentar solicitudes para ejercer derechos en su nombre. 
              <b>Si usted es un agente autorizado que quiere hacer una solicitud, comuníquese con nosotros como se menciona arriba.</b>
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Revelación de incentivos económicos de California"
              idString="ca-financial-incentive-disclosure"
              isOpen="true"
            >
            <p>
              La ley de privacidad de California exige que le informemos si le damos un programa, beneficio u otra oferta relacionada con la recopilación 
              eliminación o venta de información personal, que se define como un “incentivo económico”. Podemos ofrecerle ciertos incentivos económicos que 
              pueden dar lugar a diferentes precios, tasas o niveles de calidad (por ejemplo, concursos y programas de remisiones). Por medio de estas 
              ofertas, los consumidores nos dan cierta información personal (como nombres, correos electrónicos, teléfonos) cuando deciden participar en 
              nuestros programas. No hay obligación de participar y los consumidores pueden decidir dejar de hacerlo en cualquier momento. La información 
              de cada programa está en la oferta del programa. Ofrecemos estos programas, entre otros, para mejorar nuestra relación con usted de manera 
              que pueda disfrutar más de nuestros productos/servicios a un precio más bajo. Invertimos mucho en nuestro marketing y nuestras marcas, en 
              parte para que podamos ofrecer programas a nuestros clientes. Los datos del consumidor son valiosos para nuestro negocio solo cuando se 
              combinan con una cantidad suficiente de otros datos del consumidor y después de que los mejoramos con nuestras iniciativas aquí descritas. 
              El valor para nuestro negocio de la información personal de cualquier consumidor individual depende de una serie de factores incluyendo, 
              por ejemplo, si aprovecha cualquier oferta y en qué medida lo hace, si decide dejar de participar en alguna oferta, y en qué medida, y si 
              podemos mejorar los datos mediante nuestras iniciativas aquí descritas. Nuestra capacidad para crear cualquier valor a partir de los 
              programas se basa en gran medida en nuestra capacidad para aprovechar esas propiedades intelectuales. No calculamos el valor de los datos 
              de los consumidores en nuestra contabilidad.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Solicitudes de datos del consumidor en California"
              idString="verifiable-client-requests"
              isOpen="true"
            >
            <p>
              En 2022, recibimos 1,401 solicitudes de datos del consumidor de consumidores de California. Esto incluye solicitudes de personas para saber, acceder o eliminar sus datos.
              También recibimos 957,500 solicitudes que prefirieron no compartir información.
            </p>
            <p>
              Este es un resumen de esas solicitudes de los consumidores:
              <br>
              <b>254</b> solicitudes para saber/acceder
              <br>
              <b>1,147</b> solicitudes para eliminar
              <br>
            </p>
            <p>Nos ha llevado un promedio de 9 días procesar y responder a las solicitudes.</p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Aviso de privacidad federal "
            idString="rocket-family-of-companies"
            isOpen="true"
          >
            <p>Algunas empresas del grupo de empresas Rocket deben hacer un Aviso de privacidad extra según la Ley Gramm-Leach-Bliley Act. 
              Para su conveniencia, abajo incluimos estos avisos. 
            </p>
            <ul class="sprk-u-mll">
              <li>Rocket Loans</li>
              <li>Rocket Money</li>
              <li>Rocket Mortgage</li>
              <li>Rocket Card</li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
      </div>
    </div>
    <!-- 2/5 stack w/table of contents -->
    <div sprkStackItem class="sprk-o-Stack__item--two-fifths@m sprk-o-Stack sprk-o-Stack--end-column@m c-SideBar">
      <!-- print button -->
      <div class="print-button--container print-button--wide">
        <button sprkButton variant="secondary" id="print-button" (click)="printPrivacyPolicy()">Imprimir Política de Privacidad</button>
      </div>
      <!-- table of contents -->
      <sprk-card idString="default" class="table-of-contents--wide">
        <sprk-stack sprkCardHeader sprkStackItem itemSpacing="medium">
          <h3 class="table-of-contents--header sprk-b-TypeDisplayFive sprk-o-Stack__item c-SideBar__header-copy">
            Índice
          </h3>
        </sprk-stack>
        <sprk-stack sprkCardContent sprkStackItem itemSpacing="medium" class="sprk-u-BackgroundColor--white">
          <div sprkStackItem sprkText variant="bodyTwo" class="table-of-contents--contents">
            <a href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
            <a href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
            <a href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
            <a href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
            <a href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
            <a href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
            <a href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
            <a href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
            <a href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
            <a href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
            <a href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
            <a href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
          </div>
        </sprk-stack>
      </sprk-card>
      <!-- nearly identical table of contents for smaller viewports: accordion at bottom of page -->
      <sprk-accordion class="table-of-contents--accordion table-of-contents--narrow" *ngIf="showTableOfContents">
        <sprk-accordion-item
          heading="Índice"
          idString="table-of-contents-narrow"
          isActive="false"
          >
          <div sprkStackItem sprkText variant="bodyTwo" class="table-of-contents--contents table-of-contents--list">
            <a href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
            <a href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
            <a href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
            <a href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
            <a href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
            <a href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
            <a href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
            <a href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
            <a href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
            <a href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
            <a href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
            <a href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
          </div>
        </sprk-accordion-item>
      </sprk-accordion>
    </div>
  </div>
</div>
