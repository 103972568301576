import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageSource } from 'app/models/page-source';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { LogoScriptService } from 'app/services/generic/logo-script.service';
import { SplitService } from 'app/modules/feature-flags/services/split.service';


@Component({
    selector: 'app-talk-to-us',
    templateUrl: './talk-to-us.component.html',
    styleUrls: ['./talk-to-us.component.scss']
})
export class TalkToUsComponent implements OnInit {

  public src: PageSource
  public pageSource = PageSource;
  ttuFlagIsActive :boolean;

  constructor(
    private logoScriptService: LogoScriptService,
    private readonly _activatedRoute: ActivatedRoute,
    private _analytics: AnalyticsService,
    private split: SplitService
  ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Talk To Us");
    this.logoScriptService.logoScript(window, document, "body {opacity: 0 !important}");

    this.getTreatments();
    this._activatedRoute.queryParams.subscribe(params => this.src = params['src']);
  }

  async getTreatments() {
    this.ttuFlagIsActive = await this.split.getTreatmentFor("ttu-rocketmoney-content");
  }

  goBack() {
    window.history.back();
  }
}
