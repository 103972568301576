<div>
    <!-- Web view, when Rocket Solar flag is not active -->
    <div class="logos logos-standard" id="standard-logo-block" *ngIf="!rslFlagIsActive">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" alt="Rocket Loans">
        <img src="/assets/img/logo/RAU-logo.svg" id="rauLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Auto">
        <img src="/assets/img/logo/TB-logo.svg" id="tbiLogo" *ngIf="!rocketMoneyFlagIsActive" alt="Rocket Money">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" *ngIf="rocketMoneyFlagIsActive" alt="Rocket Money">

    </div>

    <!-- web view, when Rocket Solar Flag is active -->
    <div class="logos logos-standard--first-row" id="standard-logo-block" *ngIf="rslFlagIsActive">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Loans">
    </div>
    <div class="logos logos-standard--second-row" id="standard-logo-block" *ngIf="rslFlagIsActive">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="removeRocketSolarRocketAuto" alt="Rocket Loans">
        <img src="/assets/img/logo/RAU-logo.svg" id="rauLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Auto">
        <img src="/assets/img/logo/RSL-logo.svg" id="rslLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Solar">
        <img src="/assets/img/logo/TB-logo.svg" id="tbiLogo" *ngIf="!rocketMoneyFlagIsActive" alt="Rocket Money">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" *ngIf="rocketMoneyFlagIsActive" alt="Rocket Money">
    </div>
    
    <!-- Mobile view, when Rocket Solar flag and Rocket Money flag both are not active -->
    <div class="logos-mobile--first-row" id="mobile-logo-block" *ngIf="!rocketMoneyFlagIsActive && !rslFlagIsActive">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" alt="Rocket Loans">
        <img src="/assets/img/logo/RAU-logo.svg" id="rauLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Auto">
    </div>

    <!-- Mobile view, when Rocket Solar flag is not active but Rocket Money flag is active -->
    <div class="logos-mobile--first-row" id="mobile-logo-block" *ngIf="rocketMoneyFlagIsActive && !rslFlagIsActive">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Loans">
    </div>
    <div class="logos-mobile--second-row" id="mobile-logo-block" *ngIf="rocketMoneyFlagIsActive && !rslFlagIsActive">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="removeRocketSolarRocketAuto" alt="Rocket Loans">
        <img src="/assets/img/logo/TB-logo.svg" id="tbiLogo" *ngIf="!rocketMoneyFlagIsActive" alt="Rocket Money">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" *ngIf="rocketMoneyFlagIsActive" alt="Rocket Money">
        <img src="/assets/img/logo/RAU-logo.svg" id="rauLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Auto">
    </div>

    <!-- Mobile view, when Rocket Solar flag and Rocket Money flag both are active -->
    <div class="logos-mobile--first-row" id="mobile-logo-block" *ngIf="rocketMoneyFlagIsActive && rslFlagIsActive">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Loans">
    </div>
    <div class="logos-mobile--second-row" id="mobile-logo-block" *ngIf="rocketMoneyFlagIsActive && rslFlagIsActive">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" *ngIf="removeRocketSolarRocketAuto" alt="Rocket Loans">
        <img src="/assets/img/logo/RAU-logo.svg" id="rauLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Auto">
        <img src="/assets/img/logo/RSL-logo.svg" id="rslLogo" *ngIf="!removeRocketSolarRocketAuto" alt="Rocket Solar">
        <img src="/assets/img/logo/TB-logo.svg" id="tbiLogo" *ngIf="!rocketMoneyFlagIsActive" alt="Rocket Money">
        <img src="/assets/img/logo/RMN-logo.svg" id="rmnLogo" *ngIf="rocketMoneyFlagIsActive" alt="Rocket Money">
    </div>
</div>
