<single-column>
  <div class="text-center" *ngIf="cancelDeleteModal && !cancelled && !isComplete">
    <h1 class="sprk-b-TypeDisplayOne">Before we cancel your account deletion request.</h1>
    <img src="/assets/img/Email-Unverified.svg" class="center-block">
  </div>
  <div class="text-center" *ngIf="spinnerOn && !cancelDeleteModal && !cancelled && !isComplete">
    <h1 class="sprk-b-TypeDisplayOne">Please wait while we cancel your account deletion request.</h1>
    <div class="spinner">
      <ngx-spinner name="loader"></ngx-spinner>
    </div>
    <img src="/assets/img/Email-Unverified.svg" class="center-block">
  </div>
  <div class="text-center" *ngIf="cancelled && isComplete">
    <h1 class="sprk-b-TypeDisplayOne">Success! Your request for account deletion has been cancelled.</h1>
    <img src="/assets/img/Email-Success.svg" class="center-block">
  </div>
  <div class="text-center" *ngIf="noDeletionReq && !cancelled && isComplete">
    <h1 class="sprk-b-TypeDisplayOne">Deletion Request Not Found!</h1>
    <p>No deletion request currently exists for your account. Please <a routerLink="/talk-to-us">contact us</a> if you have any questions.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block">
  </div>
  <div class="text-center" *ngIf="genericError && !cancelled && isComplete">
    <h1 class="sprk-b-TypeDisplayOne">Generic Error</h1>
    <p>Uh oh! Something isn't working. Please try to <a routerLink="/talk-to-us">contact us</a>.</p>
    <img src="/assets/img/Email-Error.svg" class="center-block">
  </div>
</single-column>

<app-modal *ngIf="cancelDeleteModal" width="580">
  <p class="text-center">Are you sure you want to cancel the deletion request for your account?</p>
  <div class="buttons">
      <button sprkButton class="sprk-c-Button--secondary" (click)="closeModal()" id="modalCancel">No</button>
      <button sprkButton (click)="onSubmit()" id="modalSubmit">Yes</button>
  </div>
</app-modal>
