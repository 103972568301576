<sprk-stack class="sprk-o-CenteredColumn sprk-u-phl" id="top">
    <div
      class="sprk-o-CenteredColumn sprk-u-pam sprk-o-Stack sprk-o-Stack--medium sprk-o-Stack--split@m b-Content">
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--fourth@m sprk-o-Stack--medium"
      ></div>
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--half@m sprk-o-Stack--medium"
      >
        <!-- rocket logo -->
        <img class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg" alt="Rocket Logo">
        <!-- headers and copy -->
        <div sprkStackItem id="do-not-sell-content" class="sprk-u-mbl header-title">
          <h1 sprkHeading variant="displayTwo" isPageTitle="true" class="sprk-u-mbm" >Do Not Sell or Share</h1>
          <h1 sprkHeading variant="displayTwo" class="sprk-u-mbm">My Personal Information</h1>
          <ng-container *ngIf="alertType" [ngSwitch]="alertType">
            <rckt-alert *ngSwitchCase="'success'" [type]="alertType" (closed)="dismissAlert()">
              <p>Thank you. Your request has been successfully processed.</p>
            </rckt-alert>
            <rckt-alert *ngSwitchCase="'error'" [type]="alertType" (closed)="dismissAlert()">
              <p>We couldn’t process your request due to an error on our end. Please try again later.</p>
            </rckt-alert>
          </ng-container>
              
          <p class="sprk-u-mbm">
            If you're a California, Connecticut, Oregon, Texas, or Virginia resident, you may request to opt out of the "sale" or "sharing" of your personal information to third parties and Affiliates.
            Although we don't sell your personal information in the traditional sense, we may disclose
            your personal information to third parties and our Affiliates for things like marketing and analytics, among other purposes. This may be 
            considered "selling" under state privacy law. We also may "share" personal information, which means the practice of providing your personal information to third parties and Affiliates for the purpose of
            serving you specific promotions or advertisements that might be interest based on your activity across different webites.<b>To make a request, please provide the following
            information</b> associated with your Rocket Family of Companies interactions. <b>This will
            help us to process your opt-out request.</b> This information is
            only used to process your request and not stored for other purposes.
          </p>
          <a href="#/privacy-policy">Learn more about your consumer rights</a>.
           <div class="cali-check-parent">
              <input type="checkbox" sprkCheckboxInput id="californiaCheck" class="cali-checkbox"
                 (change)="isCaliChanged($event.target.checked)" [(ngModel)]="caliChecked"/>
              <label for="californiaCheck" class="cali-label" sprkCheckboxLabel>
                I am a California, Connecticut, Oregon, Texas, or Virginia resident and certify that the information I am providing is true and accurate.
              </label>
            </div>
          <form (submit)="onSubmit()" method="post" [formGroup]="form" id="doNotSellForm" class="center-block">
           
            <div class="form-flex-box">
              <div id="first-name-parent" class="flex-item">
              <rckt-input label="First Name (Required)" for="firstName">
                <input type="text" name="firstName" formControlName="firstName" class="form-control" autocomplete="firstName" id="firstName">
                <rckt-error *ngIf="firstName.errors?.required">Please enter a first name.</rckt-error>
                <rckt-error *ngIf="firstName.errors?.pattern">Please enter a valid first name.</rckt-error>
                <rckt-error *ngIf="firstName.errors?.maxlength">Cannot be more than 50 characters.</rckt-error>
              </rckt-input>
              </div>
          
              <div id="last-name-parent" class="flex-item">
                <rckt-input label="Last Name (Required)" for="lastName">
                  <input type="text" name="lastName" formControlName="lastName" class="form-control" autocomplete="lastName" id="lastName">
                  <rckt-error *ngIf="lastName.errors?.required">Please enter a last name.</rckt-error>
                  <rckt-error *ngIf="lastName.errors?.pattern">Please enter a valid last name.</rckt-error>
                  <rckt-error *ngIf="lastName.errors?.maxlength">Cannot be more than 50 characters.</rckt-error>
                </rckt-input>
              </div>
            </div>
  
            <div class="form-flex-box">
              <div id="email-address-parent" class="flex-item">
                <rckt-input label="Email Address (Required)" for="emailAddress">
                  <input type="text" name="emailAddress" formControlName="emailAddress" class="form-control" autocomplete="emailAddress" id="emailAddress">
                  <rckt-error *ngIf="emailAddress.errors?.required">Please enter an email address.</rckt-error>
                  <rckt-error *ngIf="emailAddress.errors?.pattern">Please enter a valid email address.</rckt-error>
                  <rckt-error *ngIf="emailAddress.errors?.maxlength">Cannot be more than 128 characters.</rckt-error>
                </rckt-input>
              </div>
  
              <div id="phone-number-parent" class="flex-item">
                <rckt-input label="Phone Number (Required)" for="phoneNumber">
                  <input type="text" name="phoneNumber" formControlName="phoneNumber" class="form-control" autocomplete="phoneNumber" id="phoneNumber">
                  <rckt-error *ngIf="phoneNumber.errors?.required">Please enter a phone number.</rckt-error>
                  <rckt-error *ngIf="phoneNumber.errors?.pattern">Please enter a valid phone number.</rckt-error>
                </rckt-input>
              </div>
          </div>
          <div formGroupName="mailingAddress">
            <div class="form-flex-box">
              
              <rckt-input label="Street Address, Line 1 (Required)" class="flex-item" for="addressLine1">
                <input type="text" name="addressLine1" formControlName="addressLine1" class="form-control" autocomplete="addressLine1" id="addressLine1">
                <rckt-error *ngIf="addressLine1.errors?.required">Please enter a street address.</rckt-error>
                <rckt-error *ngIf="addressLine1.errors?.pattern">Please enter a valid street address.</rckt-error>
                <rckt-error *ngIf="addressLine1.errors?.maxlength">Cannot be more than 255 characters.</rckt-error>
              </rckt-input>
            </div>
  
            <div class="form-flex-box">
              <rckt-input label="Street Address, Line 2 (Optional)" class="flex-item" for="addressLine2">
                <input type="text" name="addressLine2" formControlName="addressLine2" class="form-control" autocomplete="addressLine2" id="addressLine2">
                <rckt-error *ngIf="addressLine2.errors?.pattern">Please enter a street address.</rckt-error>
                <rckt-error *ngIf="addressLine2.errors?.maxlength">Cannot be more than 255 characters.</rckt-error>
              </rckt-input>
            </div>
  
            <div class="form-flex-box">
              <div id="city-parent" class="flex-item">
                <rckt-input label="City (Required)" for="city">
                  <input type="text" name="city" formControlName="city" class="form-control" autocomplete="city" id="city">
                  <rckt-error *ngIf="city.errors?.required">Please enter a city.</rckt-error>
                  <rckt-error *ngIf="city.errors?.pattern">Please enter a valid city.</rckt-error>
                  <rckt-error *ngIf="city.errors?.minlength">Cannot be less than 2 characters.</rckt-error>
                  <rckt-error *ngIf="city.errors?.maxlength">Cannot be more than 50 characters.</rckt-error>
                </rckt-input>
              </div>
  
              <div id="state-parent" class="flex-item">
                <rckt-input label="State (Required)" for="state">
                  <input type="text" name="state" formControlName="state" class="form-control" autocomplete="state" id="state">
                  <rckt-error *ngIf="state.errors?.required">Please enter a state.</rckt-error>
                  <rckt-error *ngIf="state.errors?.pattern">Please enter a valid state.</rckt-error>
                  <rckt-error *ngIf="state.errors?.minlength">Must be 2 characters.</rckt-error>
                  <rckt-error *ngIf="state.errors?.maxlength">Must be 2 characters.</rckt-error>
                </rckt-input>
              </div>
              
              <div id="zip-parent" class="flex-item">
                <rckt-input label="ZIP Code (Required)" for="zipCode">
                  <input type="text" name="zipCode" formControlName="zipCode" class="form-control" autocomplete="zipCode" id="zipCode">
                  <rckt-error *ngIf="zipCode.errors?.required">Please enter a zip code.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.pattern">Please enter a valid zip code.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.minlength">Must be 5 characters.</rckt-error>
                  <rckt-error *ngIf="zipCode.errors?.maxlength">Must be 5 characters.</rckt-error>
                </rckt-input>
              </div>
            </div>
          </div>

          <div class="form-flex-box">
            <div id="recaptcha-parent" class="flex-item recaptcha">
              <div #recaptcha ></div>
            </div>
            
            <div id="do-not-sell-submit-parent" class="flex-item">
              <rckt-button type="primary" buttonStyle="block" id="do-not-sell-submit" class="do-not-sell-submit" [disabled]="IsDisableSubmit" data-analytics-click="Submit Do Not Sell">Submit</rckt-button>
            </div>
          </div>
        </form>
        </div>
      </div>
      <div
        sprkStackItem
        class="sprk-o-Stack sprk-o-Stack__item--fourth@m sprk-o-Stack--medium"
      ></div>
    </div>
  </sprk-stack>