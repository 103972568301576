import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { PageNotFoundComponent } from 'app/consumer/components/page-not-found/page-not-found.component';
import { CreateAccountFormComponent } from 'app/consumer/components/create-account-form/create-account-form.component';
import { StubCreateAccountFormComponent } from 'app/consumer/components/stub-create-account-form/stub-create-account-form.component';
import { CancelDeleteRequestComponent } from 'app/consumer/components/cancel-delete-request/cancel-delete-request.component';
import { TalkToUsComponent } from 'app/consumer/components/talk-to-us/talk-to-us.component';
import { ValidSessionCheck } from 'app/consumer/util/valid-session-check';
import { MetricsResolver } from 'app/consumer/routers/metrics.resolver';
import { TosComponent } from "../util/tos/tos.component";
import { DoNotSellComponent } from '../components/do-not-sell/do-not-sell.component';
import { CookieErrorComponent } from "app/consumer/components/cookie-error/cookie-error.component";
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { PrivacyPolicySpanishComponent } from '../components/privacy-policy-spanish/privacy-policy-spanish.component';
import { DoNotSellEsComponent } from '../components/do-not-sell/spanish/do-not-sell-spanish.component';
import { ErrorComponent } from '../components/error/error.component';
import { EmailVerifyComponent } from '../components/email-verify/email-verify.component';

const appRoutes: Routes = [
    { path: '', resolve: { url: 'externalUrlRedirectResolver' }, pathMatch: 'full' },
    { path: 'create-account', resolve: [ MetricsResolver ], component: CreateAccountFormComponent },
    { path: 'talk-to-us', component: TalkToUsComponent },
    { path: 'email/verify/:token/:redirect', component: EmailVerifyComponent },
    { path: 'email/verify/:token', component: EmailVerifyComponent },
    { path: 'change-username/claim/:username-token', component: EmailVerifyComponent },
    { path: 'cancel-delete', component: CancelDeleteRequestComponent },
    { path: 'terms-of-use', component: TosComponent },
    { path: 'do-not-sell-my-personal-information', component: DoNotSellComponent },
    { path: 'es/do-not-sell-my-personal-information', component: DoNotSellEsComponent },
    { path: 'cookie-error', component: CookieErrorComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'privacy-policy-spanish', component: PrivacyPolicySpanishComponent },
    { path: 'error', component: ErrorComponent },
    { path: '**', resolve: { url: 'externalUrlRedirectResolver' }, component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { useHash: true, anchorScrolling: 'enabled' })],
    exports: [RouterModule],
    providers: [
        MetricsResolver,
        ValidSessionCheck,
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                if (window.location.href.includes('beta') || window.location.href.includes('test')) {
                    window.location.href = 'https://dashboard.beta.rocketmortgage.com';
                } else {
                    window.location.href = 'https://dashboard.rocketmortgage.com';
                }
                
            }
        }
    ]
})
export class AppRoutingModule {}
