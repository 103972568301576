<div class="column">
    <ng-content></ng-content>
    <div class="bottomLinks">
        <p><a *ngIf="lookingForTruebill" 
            id="lookingForTruebill" 
            class="bottomLinks standalone" 
            href="//{{truebillLink}}" 
            data-analytics-click="Looking for Truebill Login"
            target="blank"
            >
            Looking for Truebill Login?
        </a></p>
        <p><a *ngIf="talk" 
            id="talk" 
            class="bottomLinks standalone" 
            routerLink="/talk-to-us"
            data-analytics-click="Talk to Us"
            >
            Need Help? Talk to Us
        </a></p>
        <p><a *ngIf="terms" 
            id="terms" 
            class="bottomLinks standalone" 
            routerLink="/terms-of-use"
            data-analytics-click="Terms Of Use"
            target="blank"
            >
            Terms Of Use
        </a></p>
        <p><a *ngIf="privacy" 
            id="privacy" 
            class="bottomLinks standalone" 
            routerLink="/privacy-policy"
            data-analytics-click="Privacy Policy"
            target="blank"
            >
            Privacy Policy
        </a></p>
        <p><a *ngIf="doNotSell" 
            id="doNotSell" 
            class="bottomLinks standalone" 
            routerLink="/do-not-sell-my-personal-information"
            data-analytics-click="Do Not Sell My Personal Information"
            target="blank"
            >
            Do Not Sell or Share My Personal Information
        </a></p>
    </div>
</div>

<app-tos *ngIf="displayTOS" (closed)="toggleTOS()"></app-tos>
