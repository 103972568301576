<div class="column">
  <h1 class="text-center sprk-b-TypeDisplayOne" id="top">Rocket Account Terms Of Use</h1>
  <span class="closeBtn" (click)="close()"></span>
  <!-- feature flag change -->
  <p *ngIf="!termsOfUseFlagIsActive"><small>Rev. July 31, 2021.</small></p>
  <p *ngIf="termsOfUseFlagIsActive"><small>Rev. March 15, 2022.</small></p>
  <!-- end of feature flag change -->
  <!-- feature flag change -->
  <p class="text-justify" *ngIf="!termsOfUseFlagIsActive" id="user-agreement-flag-off">This User Agreement (“Terms”) governs your access to and 
    use of Rocket Account Services (including but not limited to web pages, single sign-on, stored profile information, and any documentation), 
    offered by Rocket Mortgage, LLC (“Rocket Mortgage”).</p>
  <p class="text-justify" *ngIf="termsOfUseFlagIsActive" id="user-agreement-flag-on">This User Agreement (“Terms”) governs your access to and 
    use of Rocket Account Services (including but not limited to web pages, single sign-on, stored profile information, and any documentation), 
    offered by certain companies within the Rocket Family of Companies listed <a href="#/privacy-policy" target="blank">here</a> ("we" or "us").</p>
  <!-- end of feature flag change -->
  <ol class="text-justify privacy-table-of-contents">
    <li><a href="#/terms-of-use#access"><b>ACCESS TO WEBSITE AND ROCKET ACCOUNT SERVICES</b></a></li>
    <li><a href="#/terms-of-use#ownership"><b>OWNERSHIP OF CONTENT</b></a></li>
    <li><a href="#/terms-of-use#responsibilities"><b>USER RESPONSIBILITIES</b></a></li>
    <li><a href="#/terms-of-use#ip"><b>INTELLECTUAL PROPERTY</b></a></li>
    <li><a href="#/terms-of-use#communications"><b>RECORDING & MONITORING OF COMMUNICATIONS</b></a></li>
    <li><a href="#/terms-of-use#sso"><b>SINGLE SIGN-ON CREDENTIALS</b></a></li>
    <li><a href="#/terms-of-use#location"><b>LOCATION AND GOVERNING LAW</b></a></li>
    <!-- feature flag change -->
    <li *ngIf="!termsOfUseFlagIsActive"><a href="#/terms-of-use#privacy"><b>PRIVACY AND SECURITY</b></a></li>
    <li *ngIf="termsOfUseFlagIsActive"><a href="#/terms-of-use#privacy"><b>PRIVACY</b></a></li>
    <!-- end of feature flag change -->
    <li><a href="#/terms-of-use#permitted-use"><b>PERMITTED USE</b></a></li>
    <li><a href="#/terms-of-use#severability"><b>SEVERABILITY</b></a></li>
    <li><a href="#/terms-of-use#notifications"><b>NOTIFICATIONS</b></a></li>
    <li><a href="#/terms-of-use#as-is"><b>ROCKET ACCOUNT SERVICES PROVIDED “AS IS,” NO WARRANTIES</b></a></li>
    <li><a href="#/terms-of-use#revisions"><b>REVISIONS AND MODIFICATIONS</b></a></li>
  </ol>
  <p class="text-justify fix-text">BY CLICKING THE CREATE ACCOUNT BUTTON, YOU AGREE TO THESE TERMS.  IF YOU DISAGREE WITH THESE TERMS 
    OR ANY PART OF THESE TERMS, YOU MUST NOT USE OUR WEBSITES.</p>
  
  <ol class="text-justify privacy-list">
      <br id="access">
    <li><b>ACCESS TO WEBSITE AND ROCKET ACCOUNT SERVICES.</b> We grant you access to Rocket Account Services for no charge. 
      We reserve the right, at our sole discretion and without notice to you, to deny you access to Rocket Account Services. 
      We may terminate Rocket Account Services at any time, for any reason, without notice.</li>
      <br id="ownership">
    <li><b>OWNERSHIP OF CONTENT.</b> You acknowledge and agree that you gain no rights in Rocket Account Services and 
      the content they provide by accessing them. You agree not to copy or distribute any of the content contained within or accessed 
      through your use of Rocket Account Services. Rocket Mortgage grants you a limited, non-exclusive, and revokable license to use 
      Rocket Account Services and any intellectual property subject to these Terms.</li>
      <br id="responsibilities">
    <li><b>USER RESPONSIBILITIES.</b> You agree to comply with applicable laws, including, without limitation, 
      privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements.  
      You also agree to provide accurate information to Rocket Account and keep it updated, including using your real name.  
      You may not develop, support, or use software, devices, scripts, robots, or any other means or processes to scrape Rocket Account 
      Services or otherwise copy data from Rocket Account. You also may not override any security feature or bypass or circumvent any 
      access controls or use limits of the Rocket Account Service.  You may not reverse engineer, decompile, disassemble, decipher, or 
      otherwise attempt to derive the source code for the Rocket Account Service.</li>
      <br id="ip">
    <li><b>INTELLECTUAL PROPERTY.</b> All copyright, trademarks, design rights, patents, and other intellectual property rights 
      (registered and unregistered) in and on Rocket Account services belong to Rocket Companies, its affiliates, and/or third parties.
      Rocket Companies reserves all its rights in Rocket Account Services. Nothing in the Terms grants you a right or license to use any 
      trademark, design right, or copyright owned or controlled by Rocket Companies or any of its affiliates or third parties except as 
      expressly permitted. Your content remains yours, which means that you retain any intellectual property rights that you have in 
      your content.</li>
      <br id="communications">
    <li><b>RECORDING & MONITORING OF COMMUNICATIONS.</b> Your chat, email, text message, online, and telephone communications with us 
      will be monitored or recorded to improve client services and prevent fraud. You agree to be recorded and monitored when you 
      interact with us.</li>
      <br id="sso">
    <!-- feature flag change -->
    <li *ngIf="!termsOfUseFlagIsActive"><b>SINGLE SIGN-ON CREDENTIALS.</b> Single Sign-On is a service that allows you to sign in to a variety of Rocket-affiliated 
      websites (including Rocket Homes Real Estate LLC, Rocket Mortgage®, Rocket Loans® and future Rocket-branded websites) and 
      services. We’ll collect information for security purposes when you set up an account or reset your password. The email address and
      password you use to sign up for an account on one of our websites are the credentials you’ll use to authenticate with our network.
      <br>
      <br>
      You agree that you won’t share your credentials and will use reasonable efforts to keep them confidential. You agree that you’ll 
      immediately contact us if you believe your user identification, password, or identifying information has been compromised, lost, 
      or stolen. We’ll never contact you through an unsolicited email or phone call requesting your username or password. You’re solely 
      responsible for all damages or claims that may arise from any access to or use of Rocket Account Services by any person using your 
      credentials.
    </li>
    <li *ngIf="termsOfUseFlagIsActive"><b>SINGLE SIGN-ON CREDENTIALS.</b> Single Sign-On is a service that allows you to sign in to a variety of Rocket-affiliated 
      websites (including Rocket Homes Real Estate LLC, Rocket Mortgage®, Rocket Loans®, Rocket Auto, and future Rocket-branded websites) and 
      services. We’ll collect information when you set up an account or reset your password. The email address and
      password you use to sign up for an account on one of our websites are the credentials you’ll use to authenticate with our network. For 
      more information about how we process your personal information, please see the <a href="#/privacy-policy" target="blank">Rocket Family 
        of Companies Privacy Policy</a>.
      <br>
      <br>
      You agree that you won’t share your credentials and will use reasonable efforts to keep them confidential. You agree that you’ll 
      immediately contact us if you believe your user identification, password, or identifying information has been compromised, lost, 
      or stolen. We’ll never contact you through an unsolicited email or phone call requesting your username or password. You’re solely 
      responsible for all damages or claims that may arise from any access to or use of Rocket Account Services by any person using your 
      credentials.
    </li>
      <!-- end of feature flag change -->
      <br id="location">
    <li><b>LOCATION AND GOVERNING LAW.</b> We provide Rocket Account Services from within the United States of America to residents of 
      the United States. The websites accessed through Rocket Account Services provide information regarding services and products that 
      are made for use in the United States. We offer no services outside of the United States. 
      <br>
      <br>
      You agree that these Terms shall be governed by and construed in accordance with the laws of the State of Michigan, without giving 
      effect to any principles of conflicts of law. You understand and agree to resolve through final and binding arbitration the 
      following claims, disputes, or controversies arising between you and Rocket Companies, and its parents, affiliates, subsidiaries, 
      or related companies: all claims, disputes, or controversies arising from the Telephone Consumer Protection Act of 1991 (“TCPA”), 
      or state law claims similar to the TCPA. You will arbitrate TCPA claims between you and Rocket Companies or its affiliates at a 
      location the arbitrator will determine in compliance with this Governing Law provision. The arbitrator, not the court, will resolve 
      the issue of arbitrability. Any state or federal court having jurisdiction thereof may enter judgment of any award the arbitrator 
      renders. This arbitration contract is made under a transaction in interstate commerce, and the Federal Arbitration Act (“FAA”) will 
      govern its interpretation, application, enforcement, and proceedings. As the Governing Law provision indicates, the laws of the 
      State of Michigan govern the enforceability of this arbitration provision as a contract, but not the scope of this provision. 
      Neither you nor Rocket Companies and its affiliates are entitled to join or consolidate claims in arbitration by or against other 
      consumers or to arbitrate any claim as a representative or member of a class or in a private attorney general capacity. The parties 
      voluntarily and knowingly waive any right they have to a jury trial for TCPA-related matters which, based on the above, will be 
      arbitrated. You agree that any other action(s) at law or in equity arising out of or relating to these Terms or the use of this 
      website shall be filed only in the state or federal courts located in Wayne County, Michigan, and you hereby consent and submit to 
      the personal jurisdiction of such courts for the purposes of litigating.</li>
      <br id="privacy">
    <!-- feature flag change -->
    <li *ngIf="!termsOfUseFlagIsActive"><b>PRIVACY AND SECURITY.</b> Rocket Account understands the importance of protecting consumer information.  Rocket Account only 
      collects users’ first name, last name, email address, password, and phone number.  We only share the necessary information to allow 
      access to our suite of Rocket products with the Rocket companies you want to use.  To see the steps we take to protect consumer 
      information, check out the Privacy Policies for each Rocket product using Rocket Account:  
      <a href="https://www.rocketmortgage.com/legal/security-privacy">Rocket Mortgage Privacy Policy</a>, 
      <a href="https://www.rockethomes.com/terms">Rocket Homes Privacy Policy</a>, 
      <a href="https://www.rocketloans.com/app#/privacy-policy">Rocket Loans Privacy Policy</a>.
    </li>
    <li *ngIf="termsOfUseFlagIsActive">
      <b>PRIVACY.</b> We will process your personal information as described in the <a href="#/privacy-policy" target="blank">Rocket Family 
        of Companies Privacy Policy</a>.
    </li>
    <!-- end of feature flag change -->
      <br id="permitted-use">
    <li><b>PERMITTED USE.</b> You may only use Rocket Account Services to conduct business with one or more of the Rocket-affiliated 
      websites.</li>
      <br id="severability">
    <li><b>SEVERABILITY.</b> You agree that if any provision of these Terms is found to be unlawful or void, or for any reason 
      unenforceable, then that provision shall be deemed severable from the other provisions of these Terms and shall not affect the 
      validity and enforceability of such other provisions.</li>
      <br id="notifications">
    <li><b>NOTIFICATIONS.</b> We may provide you with notices via email and regular mail at the most recent address you have provided, or 
      through posting information on our website.</li>
      <br id="as-is">
    <li><b>ROCKET ACCOUNT SERVICES PROVIDED “AS IS,” NO WARRANTIES.</b> Although we’ll try to provide accurate information, Rocket Account 
      Services are provided “as is.” We make no representations or warranties, whether express or implied. We and our affiliates, officers, 
      directors, employees, and third-party suppliers disclaim any and all representations, warranties, or guarantees of any kind, whether 
      express, implied, or statutory, relating to Rocket Account Services. 
      <br>
      <br>
      No action arising out of, or pertaining to, these Terms may be brought more than one (1) year after the alleged cause of action has 
      arisen.</li>
      <br id="revisions">
    <li><b>REVISIONS AND MODIFICATIONS.</b> We reserve the right to temporarily or permanently modify or discontinue Rocket Account Services 
      for any reason without notice. We may also change these Terms from time to time. By continuing to use Rocket Account Services after 
      any modifications have been posted, you agree to the modified Terms. You further acknowledge and agree that individual modifications 
      to these Terms aren’t permitted and won’t be effective.</li>
  </ol>
  <br>
  <br>
  <div class="top-link">
    <a href="#/terms-of-use#top">Back to Top</a>
  </div>
  
