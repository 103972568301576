import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

import { UtilModule } from 'app/consumer/util/util.module';

// Spark
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';

// Routers
import { AppRoutingModule } from 'app/consumer/routers/app-routing.module';

// Directives
import { EqualValidator } from 'app/consumer/directives/equal-validator.directive';

// Services
import { RedirectService } from 'app/services/generic/redirect.service';
import { HttpService } from 'app/services/logical/create-account.service';
import { AutofocusDirective } from './directives/autofocus.directive';
import { AppHookService } from 'app/services/app-hook.service';
import { MetricsService } from 'app/services/metrics.service';
import { RcktSelectComponent, RcktOption } from './util/rckt-select/rckt-select.component';
import { LogoBlockComponent } from './components/logo-block/logo-block.component';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { SendEmailVerification } from 'app/services/logical/send-email-verification.service';
import { ScriptService } from 'app/services/script.service';

// Components
import { ConsumerComponent } from 'app/consumer/components/consumer/consumer.component';
import { CreateAccountFormComponent } from 'app/consumer/components/create-account-form/create-account-form.component';
import { StubCreateAccountFormComponent } from 'app/consumer/components/stub-create-account-form/stub-create-account-form.component';
import { PageNotFoundComponent } from 'app/consumer/components/page-not-found/page-not-found.component';
import { EmailVerifyComponent } from 'app/consumer/components/email-verify/email-verify.component';
import { CancelDeleteRequestComponent } from 'app/consumer/components/cancel-delete-request/cancel-delete-request.component';
import { TalkToUsComponent } from './components/talk-to-us/talk-to-us.component';
import { DoNotSellComponent } from './components/do-not-sell/do-not-sell.component';
import { CookieErrorComponent } from './components/cookie-error/cookie-error.component';
//TODO: Remember to remove when we take out conset componenets
import { PbdConsentComponent } from './components/privacy-by-design/pbd-consent/pbd-consent.component';
import { PbdConsentFurtherActionComponent } from './components/privacy-by-design/pbd-consent-further-action/pbd-consent-further-action.component';
import { PbdConsentPartnerComponent } from './components/privacy-by-design/pbd-consent-partner/pbd-consent-partner.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PrivacyPolicySpanishComponent } from './components/privacy-policy-spanish/privacy-policy-spanish.component';
import { DoNotSellEsComponent } from './components/do-not-sell/spanish/do-not-sell-spanish.component';
import { ErrorComponent } from './components/error/error.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'environments/environment';
@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'ra-universal' }),
        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            redirectUri: environment.auth0.redirectUri,
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
            scope: 'openid profile email offline_access',
            audience: environment.auth0.audience,
        }),
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        UtilModule,
        AppRoutingModule,
        SparkAngularModule,
        NgxSpinnerModule,
        ClipboardModule
    ],
    declarations: [
        AutofocusDirective,
        ConsumerComponent,
        CreateAccountFormComponent,
        CancelDeleteRequestComponent,
        StubCreateAccountFormComponent,
        EmailVerifyComponent,
        EqualValidator,
        PageNotFoundComponent,
        TalkToUsComponent,
        RcktSelectComponent,
        RcktOption,
        LogoBlockComponent,
        PbdConsentComponent,
        PbdConsentFurtherActionComponent,
        CookieErrorComponent,
        PbdConsentPartnerComponent,
        PrivacyPolicyComponent,
        PrivacyPolicySpanishComponent,
        DoNotSellComponent,
        DoNotSellEsComponent,
        ErrorComponent,
    ],
    providers: [
        AnalyticsService,
        HttpService,
        MetricsService,
        RedirectService,
        AppHookService,
        SendEmailVerification,
        ScriptService
    ],
    bootstrap: [ConsumerComponent]
})
export class ConsumerModule {
}
