
<sprk-icon-set></sprk-icon-set>

<div class="sprk-o-CenteredColumn sprk-u-phl sprk-u-mbl" id="top">
  <div class="sprk-o-CenteredColumn sprk-o-Stack sprk-o-Stack--medium sprk-o-Stack--split@m">
    <!-- 3/5 stack w/privacy policy -->
    <div sprkStackItem class="sprk-o-Stack__item--three-fifths@m sprk-o-Stack sprk-o-Stack--medium b-Content">
      <!-- rocket logo -->
      <img class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
      <!-- headers and copy -->
      <div sprkStackItem id="rocket-privacy-policy" class="header-title">
        <h1 sprkHeading variant="displayTwo" isPageTitle="true" class="sprk-u-mbl">
          Rocket Family Of Companies Privacy Policy
        </h1>
        <!-- print button, shows only at small viewports (under 880px) -->
        <div class="print-button--container print-button--narrow" *ngIf="showTableOfContents">
          <button sprkButton variant="secondary" id="print-button" (click)="printPrivacyPolicy()">Print Privacy Policy</button>
        </div>
        <p><a href="#/privacy-policy-spanish">Política de privacidad de la familia de compañías Rocket</a></p>
        <p><b>Effective Date</b>: January 8, 2024</p>
        <p>
          This Rocket Family of Companies Privacy Policy describes how the Rocket Family of Companies (as defined in 
          <a href="#/privacy-policy#who-we-are">"Who We Are"</a> below) process information collected on our websites and 
          applications (including mobile) or through your online interactions with us (collectively, our "Services"). 
        </p>
        <p>
          In some circumstances, we may provide you with additional or supplemental privacy notices to the extent they apply to you because of the 
          products and services you obtain from us or different laws that may apply to you. Some states may provide additional privacy rights. Please refer to the 
          <a href="#/privacy-policy#additional-information">Additional Information Regarding Your State Privacy Rights</a> section to learn more.
        </p>
        <p>
          <b>
          By using any of our Services, you acknowledge the privacy practices we describe in this Rocket Family of Companies Privacy Policy. You 
          also acknowledge that you have read this policy and other policies described.
          </b>
        </p>
      </div>
      <!-- "Who We Are" section -->
      <div sprkStackItem id="who-we-are">
        <h1 class="sprk-u-mbm">Who We Are</h1>
        <p>
          When we say "<b>Rocket Family of Companies</b>," we mean companies that are controlled by RKT Holdings, LLC and share the Rocket name 
          or are on the Rocket platform. A reference to "Rocket", "we" or "us" is a reference to the relevant Rocket Company in the Rocket Family of 
          Companies that is involved in the processing activity. This policy does not apply to Rocket affiliates that aren't in the Rocket Family of 
          Companies ("Affiliates"). The data practices of Affiliates are governed by their own privacy policies.
        </p>
        
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Rocket Family Of Companies"
            idString="rocket-family-of-companies"
            isOpen="true"
          >
            <p>The Rocket Family of Companies includes:</p>
            <ul class="sprk-u-mll">
              <li>Rocket Mortgage</li>
              <li>Rocket Loans</li>
              <li>Rocket Homes</li>
              <li>Rocket Money, Inc. (f/k/a Truebill, Inc.) (“Rocket Money”), a Rocket Company</li>
              <li>Rocket Central</li>
              <li>ForSaleByOwner.com, a Rocket Company</li>
              <li>Rocket Card</li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Information We Collect" section -->
      <div sprkStackItem id="information-we-collect">
        <h1 class="sprk-u-mbm">Information We Collect</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Information You Provide"
            idString="information-you-provide"
            isOpen="true"
          >
            <p>We collect information you provide to us. For example, we collect information from you when you create a Rocket account, fill out a 
              form or otherwise submit content through our Services, are referred to us via one of our third-party professional partner relationships, 
              make a purchase, communicate with us via third-party platforms, participate in a contest, promotion or survey, request customer 
              support or information about our Services, or otherwise communicate with us. 
            </p>
            <p>
              The specific information we collect depends on the context in which you provide it, and could include:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">Name</li>
              <li class="list-item">Email address</li>
              <li class="list-item">Postal address</li>
              <li class="list-item">Phone number</li>
              <li class="list-item">Social security number</li>
              <li class="list-item">Location and approximate value of your property</li>
              <li class="list-item">Vehicle information, such as make, model, VIN and vehicle features</li>
              <li class="list-item">Age and/or date of birth</li>
              <li class="list-item">Demographic information, such as race, ethnicity and gender gathered pursuant to federal requirements</li>
              <li class="list-item">Related loan products or services you have used in the past</li>
              <li class="list-item">Financial information, such as income, assets and net worth</li>
              <li class="list-item">Home ownership status and employment status</li>
              <li class="list-item">Insurance information</li>
              <li class="list-item">Other profile data, including your interests and preferences</li>
              <li class="list-item">Any other information you choose to provide</li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
            heading="Information We Automatically Collect"
            idString="information-we-automatically-collect"
            isOpen="true"
          >
            <p>We automatically collect certain information about your interactions with us or our Services, including:</p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                <p><b>Transactional Information: </b>When you make a purchase or complete a transaction, we collect information about the transaction, 
                  such as product or service details, financial details and the date and location of the transaction.
                </p>
              </li>
              <li class="list-item">
                <p><b>Device and Usage Information: </b>We collect information about how you access our Services, including data about the device and 
                  network you use, such as hardware model, operating system version, mobile network, IP address, unique device identifiers and device 
                  regional and language settings, browser type, and app version. We also collect information about your activity on our Services, such 
                  as access times, pages viewed, links clicked, products and services considered, and the page you visited before navigating to our 
                  Services.
                </p>
              </li>
              <li class="list-item">
                <p><b>Your Content: </b>As you allow through your device or application, we may collect information and content stored on your device, 
                  such as photos.
                </p>
              </li>
              <li class="list-item">
                <p><b>Location Information: </b>In accordance with your device permissions, we may collect information about the precise location of 
                  your device. You may stop the collection of precise location information at any time (see the 
                  <a href="#/privacy-policy#your-choices">Your Choices</a> section below for details).
                </p>
              </li>
              <li class="list-item">
                <p><b>Information Collected by Cookies and Similar Tracking Technologies: </b> We (and those who perform work for us) use tracking 
                  technologies, such as cookies and web beacons, to collect information about your interactions with the Services.
                </p> 
                <div class="sprk-u-mll">
                  <p>
                    <b>Cookies</b> are small data files stored on your hard drive or in device memory that help us improve our Services and your 
                    experience, see which areas and features of our Services are popular and which emails and advertisements you view, authenticate 
                    users, reconstruct or observe activity relating to a session or by a user for troubleshooting, issue resolution, and other 
                    purposes, and count visits to our websites.
                  </p>
                  <p>
                    <b>Web beacons</b> (also known as <b>"pixel tags"</b> or <b>"clear GIFs"</b>) are electronic images that we use on our Services and 
                    in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. In our mobile apps, we 
                    may also use technologies that are not browser-based like cookies.
                  </p>
                  <p>
                    For example, our apps may include <b>software development kits</b> (known as SDKs) which are code that sends information 
                    about your use to a server. These SDKs allow us to track our conversions, bring you advertising both on and off the Service, 
                    and provide you additional functionality. <b>For more information about these tracking technologies and how to disable them, 
                      see the <a href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a> section below.</b>
                  </p>
                </div>
              </li>
            </ul>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Information We Collect From Other Sources"
            idString="information-we-collect"
            isOpen="true"
          >
            <p>
              We obtain information from third-party sources in certain situations. For example, we may collect information about you from identity 
              or other verification services, credit bureaus (including your credit report), advertising networks, mailing list providers, service 
              providers and data brokers. Our Services also may allow or require you to integrate or connect your Rocket account with third-party 
              services through service providers (such as Plaid).
            </p>
            <p>
              The information we receive from such third parties varies depending on the information made available by those entities. Examples of 
              the types of information we receive include financial account information, information about account balance, information about 
              credit accounts, information about loan accounts, information about investment accounts, identifiers and information about account 
              owners, information about account transactions and employment information.
            </p>
              
            <p>
              Additionally, if you create or log into your Rocket account through a third-party platform, such as Facebook or Google, we'll have 
              access to certain information from that platform, such as your name, birthday, and profile picture, in accordance with the authorization 
              procedures determined by such platform.
            </p>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Information We Derive"
            idString="information-we-derive"
            isOpen="true"
          >
            <p>
              We may derive information or draw inferences about you based on the information we collect. For example, we may make inferences about 
              your location based on your IP address or city or postal code or infer that you are looking to purchase certain products based on 
              information you provide to us and your browsing behavior and past purchases.
            </p>
          </sprk-accordion-item>
          <sprk-accordion-item
            heading="Plaid Technologies"
            idString="plaid-technologies"
            isOpen="true"
          >
            <p>
              Rocket enables you to use Plaid Technologies, Inc. to gather data from financial institutions. By using the Services, you acknowledge 
              and agree that your information will be processed in accordance with the 
              <a href="https://plaid.com/legal/#consumers" target="blank">Plaid Privacy Policy</a> and you grant Rocket and Plaid the same rights, 
              power and authority as specified therein.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "How We Use Your Information" section -->
      <div sprkStackItem id="how-we-use">
        <h1 class="sprk-u-mbm">How We Use Your Information</h1>
        <p>We use the information we collect to provide our Services to you.</p>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Other Reasons to Collect Information"
              idString="other-reasons-to-collect"
              isOpen="true"
            >
            <p>
              We also use the information we collect to:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                Fulfill or deliver the services you requested
              </li>
              <li class="list-item">
                Provide, maintain, improve and develop new products, services and features, including debugging and repairing errors in our 
                Services
              </li>
              <li class="list-item">
                Personalize your experience with us
              </li>
              <li class="list-item">
                Send you technical notices, security alerts, support messages and other transactional or relationship messages, including responding 
                to your inquiries and comments
              </li>
              <li class="list-item">
                Communicate with you about products, services, and events offered by Rocket and others and provide news and information 
                that we think will interest you. <b>See the <a href="#/privacy-policy#your-choices">Your Choices</a> section below for information 
                about how to opt out of these communications at any time</b>
              </li>
              <li class="list-item">
                Monitor and analyze trends, usage, and activities in connection with our products and services
              </li>
              <li class="list-item">
                Personalize the advertisements you see on third-party platforms and websites. <b>For more information, see the 
                <a href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a> section below</b>
              </li>
              <li class="list-item">
                Show you offers for third-party services personalized to you, based on information we have collected about you
              </li>
              <li class="list-item">
                Facilitate contests, sweepstakes and promotions, and process and deliver entries and rewards
              </li>
              <li class="list-item">
                Detect, investigate, and help prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity, and 
                help protect the rights and property of Rocket and others
              </li>
              <li class="list-item">
                Comply with our legal and financial obligations
              </li>
              <li class="list-item">
                Create de-identified, anonymized or aggregated information
              </li>
              <li class="list-item">
                Fulfill any other purpose at your direction
              </li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "How We Share Your Information" section -->
      <div sprkStackItem id="how-we-share">
        <h1 class="sprk-u-mbm">How We Share Your Information</h1>
        <p>
          We share personal information in the following circumstances or as otherwise described in this policy.
        </p>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Sharing Among Rocket Family Of Companies"
              idString="sharing-among-rocket-family-of-companies"
              isOpen="true"
            >
            <p>
              The <b>Rocket Family of Companies</b> use and share your information with each other in accordance with this 
              <b>Rocket Family of Companies Privacy Policy</b>. This allows the <b>Rocket Family of Companies</b> to manage and provide 
              and offer you a variety of their services through a broad lifecycle of your personal and financial needs.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Sharing With Affiliates"
              idString="sharing-with-affiliates"
              isOpen="true"
            >
            <p>
              Rocket also shares your information with our Affiliates. For example, we share, or may share, your information:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                With Affiliates that act as vendors, service providers and consultants that access personal information to perform services 
                for us, such as companies that assist us with call center/directory services
              </li>
              <li class="list-item">
                With Affiliates for their own services and marketing purposes
              </li>
              <li class="list-item">
                With Affiliates for regulatory reporting purposes and capturing performance metrics
              </li>
              <li class="list-item">
                With Affiliates if we believe (1) that your actions are inconsistent with our user agreements or policies, (2) that you have 
                violated the law, or (3) it's necessary to protect the rights, property and safety of Rocket, our users, the public or others
              </li>
              <li class="list-item">
                In connection with, or during negotiations concerning, any merger, sale of company assets, financing or acquisition of all or 
                a portion of our business by another company
              </li>
              <li class="list-item">
                With Affiliates with your consent or at your direction
              </li>
            </ul>
            <br>
            <p>
              We also share aggregated or de-identified information that cannot reasonably be used to identify you.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Sharing With Other Third Parties"
              idString="sharing-with-other-third-parties"
              isOpen="true"
            >
            <p>
              Rocket shares your information with non-affiliated third parties in the following circumstances:
            </p>
            <ul class="sprk-u-mll">
              <li class="list-item">
                We share personal information with vendors, service providers and consultants that access personal information to perform 
                services for us, such as companies that assist us with web hosting, shipping and delivery, payment processing, fraud prevention, 
                customer service, data analytics, property title and appraisal services, technical support, marketing and advertising.
              </li>
              <li class="list-item">
                If you submit a product review or post content in another public area of our Services, we share this information publicly on 
                our Services.
              </li>
              <li class="list-item">
                If you choose to use integrations we offer on our Services, such as sharing your location through our Google Maps integration, 
                we may share certain information with the integration partners.
              </li>
              <li class="list-item">
                We share personal information with third parties for their own services and marketing purposes, analytics, and in some cases to 
                jointly market and provide products and services with third parties.
              </li>
              <li class="list-item">
                When you request information about services offered by other companies, we may disclose information about you to those companies 
                so that we or they can notify you of, and determine if you qualify for, products or services.
              </li>
              <li class="list-item">
                We may disclose personal information if we believe that disclosure is in accordance with, or required by, any applicable law or 
                legal process, including lawful requests by public authorities to meet national security or law enforcement requirements.
              </li>
              <li class="list-item">
                We may share personal information if we believe (1) that your actions are inconsistent with our user agreements or policies, 
                (2) that you have violated the law, or (3) it's necessary to protect the rights, property and safety of Rocket, our users, the 
                public, or others.
              </li>
              <li class="list-item">
                We share personal information with our lawyers and other professional advisors where necessary to obtain advice or otherwise 
                protect and manage our business interests.
              </li>
              <li class="list-item">
                We may share personal information in connection with, or during negotiations concerning, any merger, sale of company assets, 
                financing or acquisition of all or a portion of our business by another company.
              </li>
              <li class="list-item">
                We share personal information with your consent or at your direction, including but not limited to through third-party 
                integrations you choose to enable.
              </li>
              <li class="list-item">
                We also share aggregated or de-identified information that cannot reasonably be used to identify you.
              </li>
              <li class="list-item">
                If you have opted-in or consented to receive text messages, your consent to such messages will not be shared 
                with any third parties.
              </li>
            </ul>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Advertising and Analytics" section -->
      <div sprkStackItem id="advertising-and-analytics">
        <h1 class="sprk-u-mbm">Advertising and Analytics</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Web and Mobile Apps"
              idString="web-and-mobile-apps"
              isOpen="true"
            >
            <p>
              We share personal information to allow others to provide analytics services and serve advertisements across the web and in mobile 
              apps. These entities may use <b>cookies, web beacons, device identifiers</b> and other technologies to collect information about 
              your use of our Services and other websites and applications, including your IP address, web browser, mobile network information, 
              pages viewed, time spent on pages or in mobile apps, links clicked and conversion information.
            </p>
            <p>
              This information may be used by Rocket and others to, among other things, analyze and track data, reconstruct or observe activity 
              relating to a session, determine the popularity of certain content, deliver advertising and content targeted to your interests on 
              our Services and other websites, and better understand your online activity.
            </p>
            <p>
              <b>
                For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral 
                advertising purposes, please visit <a href="http://www.aboutads.info/choices" target="blank">www.aboutads.info/choices</a>. 
              </b>
              Your device may also include a feature ("Limit Ad Tracking" on iOS or "Opt Out of Interest-Based Ads" or "Opt Out of Ads 
              Personalization" on Android) that allows you to opt out of having certain information collected through mobile apps used for 
              behavioral advertising purposes. Certain state privacy laws also provide their residents with rights relating to submitting a 
              direct opt-out for the sharing of personal information for purposes of cross-contextual, or interest-based, advertising.
              For more information, please see the Additional Information Regarding Your State Privacy Rights section below.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Third Parties"
              idString="third-parties"
              isOpen="true"
            >
            <p>
              We also share personal information to work with third parties to serve ads to you as part of customized campaigns on third-party 
              platforms (such as Facebook). As part of these ad campaigns, we or the third-party platforms may convert information about you, 
              such as your email address or phone number, into a unique value that can be matched with a user account on these platforms to 
              allow us to learn about your interests and serve you advertising that is customized to your interests. Note that third-party 
              platforms may offer you choices about whether you see these types of customized ads.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Children's Privacy" section -->
      <div sprkStackItem id="childrens-privacy">
        <h1 class="sprk-u-mbm">Children's Privacy</h1>
        <p>
          Rocket products are not intended for children. We don't direct our Services to children under 16, or knowingly attempt to 
          solicit or receive information from children.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "California Shine the Light" section -->
      <div sprkStackItem id="california-shine-the-light">
        <h1 class="sprk-u-mbm">California Shine The Light</h1>
        <p>
          California law permits residents of California to request certain details about how their information is shared with third 
          parties for direct marketing purposes. If you're a California resident and would like to make such a request, please call
          us at <a href="tel:888-444-7492">888-444-7492</a>.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Processing of Information" section -->
      <div sprkStackItem id="processing-of-information">
        <h1 class="sprk-u-mbm">Processing Of Information In The United States and Other Countries</h1>
        <p>
          Rocket is headquartered in the United States, and the Rocket Family of Companies offers goods and services to consumers in the United 
          States. We have operations and vendors in the United States and other countries. Therefore, we and those that perform work for us may 
          transfer your personal information to, or store or access it in, jurisdictions that may not provide levels of data protection that are 
          equivalent to those of your home jurisdiction.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Your Choices" section -->
      <div sprkStackItem id="your-choices">
        <h1 class="sprk-u-mbm">Your Choices</h1>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Right to Correct Account Information"
              idString="right-to-correct-account-information"
              isOpen="true"
            >
            <p>
              You may update and correct certain account information, or delete your account, by logging into your account or calling us at 
              <a href="tel:888-444-7492">888-444-7492</a>. Please note that we may retain certain information as required by law or for our 
              legitimate business purposes. For more information, please see <a href="#/privacy-policy#additional-information">Additional Information Regarding Your State Privacy Rights</a>.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Location Information"
              idString="location-information"
              isOpen="true"
            >
            <p>
              When you first launch any of our mobile apps that collect precise location information,
              or when you visit portions of our Services that collect precise location information,
              you'll be asked to consent to the collection of this information. If you initially consent to our collection of such location information, 
              you can subsequently stop the collection of this information at any time by changing the preferences on your mobile device.
              It is important to note that some Services may not function without precise location information. You may also stop a mobile app's
              collection of location information by following the standard uninstall process to remove the app from your device.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Do Not Track Signals"
              idString="do-not-track-signals"
              isOpen="true"
            >
            <p>
              You may be using an Internet browser that can communicate your privacy preferences to the website, including requests not to 
              track your usage and browsing history. Our websites are configured to recognize those browser signals.
              If we recognize one of these signals – which may be referred to or are labeled by your browser as “do not track” or
              “opt-out preference” or “global preference” signals – we give you the option of submitting a request to opt-out of sale/sharing.
              For more information on opting out of the sale/sharing of personal information, please see Additional Information Regarding Your State Privacy Rights, below.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Changes To This Policy" section -->
      <div sprkStackItem id="changes-to-this-policy">
        <h1 class="sprk-u-mbm">Changes To This Policy</h1>
        <p>
          We may change this <b>Rocket Family of Companies Privacy Policy</b> from time to time. If we make changes, we'll notify you by revising the 
          date at the top of this policy and, in some cases, we may provide you with additional notice, such as adding a statement to our 
          website or sending you a notification. We encourage you to review this policy regularly to stay informed about our information 
          practices and the choices available to you.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <div sprkStackItem id="contact-information">
        <h1 class="sprk-u-mbm">Contact Information</h1>
        <p>
          If you have questions regarding this <b>Rocket Family of Companies Privacy Policy</b>, our information practices or other aspects of privacy 
          relating to our Services, call us at <a href="tel:888-444-7492">888-444-7492</a>.
        </p>
        <a class="top-link" href="#" (click)="scrollToTop($event)">Back to Top</a>
      </div>
      <!-- "Additional Information" section -->
      <div sprkStackItem id="additional-information">
        <h1 class="sprk-u-mbm">ADDITIONAL INFORMATION REGARDING YOUR STATE PRIVACY RIGHTS</h1>
        <p>
          Some states require that we provide additional information regarding our privacy practices and your privacy rights.
          States that have passed comprehensive privacy laws giving residents specific privacy rights include: California,
          Colorado, Connecticut, Delaware, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah and Virginia.
        </p>
        <p>
          Below we provide you additional notices about our privacy practices 
          and consumer rights relating to your information. Some of the rights may depend on when the law is effective and whether you are a resident of a state with specific privacy laws,
          as well as other factors. Also, some of the companies within the Rocket Family of Companies are financial institutions subject 
          to other regulatory privacy frameworks, such as the Gramm Leach Bliley Act (GLBA), and the privacy disclosure and rights described in this 
          section may not apply.
        </p>

        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Notice of Information Collected"
              idString="notice-of-information-collected"
              isOpen="true"
            >
            <p>
              In the preceding 12 months, we have collected the following categories of personal information:
            </p>
            <sprk-table idString="collection-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Category</th>
                  <th>Examples</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    Name, alias, postal address, online identifier (including IP address), email address, Social Security number, driver's license 
                    number, or other similar identifiers.
                  </td>
                </tr>
                <tr>
                  <td>Client Records</td>
                  <td>
                    Employer name, employment history, bank account number, credit card number, debit card number, and other financial information 
                    (including credit report, salary, and net worth).
                  </td>
                </tr>
                <tr>
                  <td>Protected Classification Characteristics Under State Or Federal Law</td>
                  <td>Age, gender, military status and marital status.</td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>Records of property, products or services purchased.</td>
                </tr>
                <tr>
                  <td>Internet Or Other Similar Network Activity</td>
                  <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
                </tr>
                <tr>
                  <td>Geolocation Data</td>
                  <td>Physical location or movements.</td>
                </tr>
                <tr>
                  <td>Sensory Data</td>
                  <td>Audio (such as call recordings) or the contents of videos you choose to upload.</td>
                </tr>
                <tr>
                  <td>Professional Or Employment-Related Information</td>
                  <td>Current employer or employment status.</td>
                </tr>
                <tr>
                  <td>Inferences Drawn From Other Personal Information</td>
                  <td>Preferences, characteristics and approximate location.</td>
                </tr>
                <tr>
                  <td>Sensitive Personal Information</td>
                  <td>
                    Social Security Number, driver’s license, state identification card, or passport number; account log-In, financial account, 
                    debit card, or credit card number in combination with any required security or access code, password, or credentials allowing 
                    access to an account; precise geolocation; racial or ethnic origin, religious or philosophical beliefs, or union membership; the 
                    contents of a consumer’s mail, email and text messages, unless the business is the intended recipient of the communication. 
                  </td>
                </tr>
              </tbody>
            </sprk-table>
            <p>
              <b>For details about the precise data points we collect and the categories of sources of such collection, please see the 
              <a href="#/privacy-policy#information-we-collect">Information We Collect</a> section.</b> We collect personal information 
              for the business and commercial purposes described in the <a href="#/privacy-policy#how-we-use">How We Use Your Information</a> 
              section.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Disclosure Of Personal Information"
              idString="disclosure-of-personal-information"
              isOpen="true"
            >
            <p>
              In the preceding 12 months, we've disclosed the following categories of personal information for business or commercial purposes 
              to the following categories of recipients:
            </p>
            <sprk-table idString="disclosure-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Category of Personal Information</th>
                  <th>Categories of Recipients</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>
                    Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service 
                    providers (such as internet service providers, data analytics providers, email service providers, data storage providers, 
                    customer support providers, account management providers, operating systems and platforms).
                  </td>
                </tr>
                <tr>
                  <td>Client Records</td>
                  <td>
                    Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service 
                    providers (such as internet service providers, data analytics providers, email service providers, data storage providers, 
                    customer support providers, account management providers, operating systems and platforms).
                  </td>
                </tr>
                <tr>
                  <td>Protected Classification Characteristics</td>
                  <td>Service providers, Affiliates, mortgage partners, and partner companies.</td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>
                    Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service 
                    providers (such as internet service providers, data analytics providers, email service providers, data storage providers, 
                    customer support providers, account management providers, operating systems and platforms).
                  </td>
                </tr>
                <tr>
                  <td>Internet Or Other Network Activity</td>
                  <td>
                    Advertising networks and service providers (such as internet service providers, data analytics providers, data storage 
                    providers, operating systems and platforms).
                  </td>
                </tr>
                <tr>
                  <td>Geolocation Data</td>
                  <td>Partners and service providers.</td>
                </tr>
                <tr>
                  <td>Sensory Data</td>
                  <td>Partners.</td>
                </tr>
                <tr>
                  <td>Professional Or Employment-Related Information</td>
                  <td>Service providers and partners.</td>
                </tr>
                <tr>
                  <td>Inferences Drawn From Other Personal Information</td>
                  <td>
                    Partners, advertising networks, and service providers (such as internet service providers, data analytics providers, email 
                    service providers, data storage providers, customer support providers, account management providers, operating systems and 
                    platforms).
                  </td>
                </tr>
                <tr>
                  <td>Sensitive Personal Information</td>
                  <td>
                    Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, service providers 
                    (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support 
                    providers, account management providers, operating systems and platforms), affiliates, and partner companies.
                  </td>
                </tr>
              </tbody>
            </sprk-table>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Right to Limit Processing of Sensitive Personal Information"
              idString="right-to-limit-processing-of-sensitive-personal-information"
              isOpen="true"
            >
            <p>
              In California, you have the right to limit our processing of your Sensitive Personal Information to only uses which are necessary to 
              perform the services or provide the goods reasonably expected by an average consumer who requests such goods or services. When we collect 
              your Sensitive Personal Information, we collect and use that information to perform the services or provide the goods for which that 
              information is necessary and as reasonably expected by you.
            </p>
            <p>
              Under other state privacy laws, we are only permitted to collect certain pieces of Sensitive Personal Information after we have obtained your consent to do so.
              Where required, we get your agreement to collect and use Sensitive Personal Information. 
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Sale Of Personal Information"
              idString="sale-of-personal-information"
              isOpen="true"
            >
            <p>
              State privacy laws require certain disclosures for companies that "sell" personal information 
              pursuant to the respective state's privacy law. Each state defines the "sale" of data differently. In California, the "sale" of data 
              means certain scenarios in which Rocket has shared personal information with third parties or Affiliates, in exchange for valuable 
              consideration. Other states define the "sale" of data as Rocket exchanging personal information for 
              monetary consideration with a non-affiliated third party. Under this definition, we do not "sell" your personal information.
            </p>
            <p>
              Under California's definition, we "sell" personal information to enable our Affiliates and certain third parties to use your 
              information in accordance with their privacy policies. We "sell" the following categories of personal information to the following 
              categories of third parties:
            </p>
            <sprk-table idString="sale-of-personal-information-table">
              <thead sprkTableHead>
                <tr>
                  <th>Category of Personal Information</th>
                  <th>Categories of Third Parties</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Identifiers</td>
                  <td>Affiliates and non-affiliated third parties.</td>
                </tr>
                <tr>
                  <td>Categories Of Personal Information Described In Subdivision (e) Of Section 1798.80</td>
                  <td>Affiliates and non-affiliated third parties.</td>
                </tr>
                <tr>
                  <td>Commercial Information</td>
                  <td>Affiliates and non-affiliated third parties.</td>
                </tr>
                <tr>
                  <td>Internet Or Other Network Activity</td>
                  <td>Affiliates and non-affiliated third parties.</td>
                </tr>
                <tr>
                  <td>Inferences Drawn From Other Personal Information</td>
                  <td>Affiliates and non-affiliated third parties.</td>
                </tr>
              </tbody>
            </sprk-table>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Right to Opt-Out of Selling"
              idString="right-to-opt-out-of-selling"
              isOpen="true"
            >
            <p>
              You have the right to opt out of the "sales" described above at any time by visiting our 
              <a href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information</a> webpage.
              We do not knowingly sell or share personal information about consumers under the age of 16.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Right to Opt-Out of Personal Information Being Shared or Used In Targeted Advertising"
              idString="right-to-opt-out-of-personal-information"
              isOpen="true"
            >
            <p>
              California requires that we notify you if we “Share” your personal information with third parties. In California, “Sharing” means 
              we make available or otherwise disclose your information to third parties for cross-context behavioral advertising. Further, 
              “cross-context behavioral advertising” means targeting of advertising to you based on your personal information obtained from 
              your activity across businesses, websites, apps, and services other than our Services.
            </p>
            <p>
              Similarly, privacy laws in other states require we notify you if we use your information for Targeted 
              Advertising. Targeted Advertising means displaying advertisements to you where the advertisement is selected based on personal information 
              obtained from your activities over time and across nonaffiliated websites or online applications to predict your preferences or interests.
            </p>
            <p>
              Accordingly, we "Share" your personal information with third parties for purposes of cross-contextual advertising,
              and we use your information for Targeted Advertising. Specifically, we share your identifiers and internet or other network activity with our advertising partners. 
            </p>
            <p>
              <b>You have the right to opt out of "sharing" or Targeted Advertising by accessing our 
              <a href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information</a> webpage.
              We also honor browser “Do Not Track” signals, as noted above.</b>
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="Retention of Personal Information"
              idString="retention-of-personal-information"
              isOpen="true"
            >
            <p>
              We will not retain your personal information for longer than is reasonably necessary to carry out the purposes we disclose in this policy.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="State Consumer Data Rights"
              idString="state-consumer-data-rights"
              isOpen="true"
            >
            <p>
              Subject to certain limitations, you have the right to (1) request to know more about the categories and specific pieces of 
              personal information we collect, use, disclose and sell, and to access your information, (2) request deletion of your personal information, (3) request correction 
              of your personal information and (4) not be discriminated against for exercising these rights.
            </p>
            <p>
              <b>You may make these requests by 
                <a href="https://www.rocketmortgage.com/privacy-rights/data-request?qls=QRD_12345678.0123456789" target="blank">
                starting a request online</a> or calling <a href="tel:888-444-7492">888-444-7492</a>.
              </b> 
              We'll verify your request by asking you to provide information related to your recent interactions with us.
            </p>
            <p>
              If we receive your request from an authorized agent, we may ask for evidence that you've provided such agent with a power of attorney, 
              or that the agent otherwise has valid written authority to submit requests to exercise rights on your behalf. 
              <b>If you're an authorized agent seeking to make a request, please contact us as set out above.</b>
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="California Financial Incentive Disclosure"
              idString="ca-financial-incentive-disclosure"
              isOpen="true"
            >
            <p>
              California’s privacy law requires that we notify you if we provide you a program, benefit, or other offering, related to the collection, 
              deletion, or sale of personal information, which it defines as a “financial incentive.” We may offer you certain financial incentives that 
              may result in different prices, rates, or quality levels (for example, contests and referral programs). Through these offerings, consumers 
              provide us with some personal information (e.g., names, emails, phone numbers) when they opt-in to our programs. There is no obligation to 
              opt-in and consumers may opt-out at any time. The details of each program are contained in the program offering. We offer these programs, 
              among other things, to enhance our relationship with you so you can enjoy more of our products/services at a lower price. We invest heavily 
              in our marketing and brands, in part, so we can provide programs to our customers. Consumer data is valuable to our business only when it is 
              combined with a sufficient number of other consumer data and after it is enhanced by our efforts herein described. The value to our business 
              of any individual consumer's personal information is dependent on a number of factors, including, for example, whether and to what extent you 
              take advantage of any offerings, whether and to what extent you opt out of any offerings, and whether we are able to enhance the data through 
              our efforts herein described. Our ability to create any value from the programs is heavily based on our ability to leverage said intellectual 
              properties. We do not calculate the value of consumer data in our accounting statements.
            </p>
          </sprk-accordion-item>
        </sprk-accordion>
        <sprk-accordion class="accordion-header">
          <sprk-accordion-item
              heading="California Consumer Data Requests"
              idString="ca-verifiable-client-requests"
              isOpen="true"
            >
            <p>
              In 2023, we received 421 consumer data requests from California consumers. This includes requests from people to know, access,
              or delete their data. We also received 48,406 requests from people who opted out of data sharing.
            </p>
            <p>
              Here’s a breakdown of those consumer requests:
              <br>
              <b>119</b> Right To Know/Access requests
              <br>
              <b>302</b> Right To Delete requests
            </p>
            <p>It's taken us a median of 2 days to process and respond to each request.</p>
          </sprk-accordion-item>
        </sprk-accordion>
        <a class="top-link margin--bottom" href="#/privacy-policy#top">Back to Top</a>
      </div>
      <!-- "Federal Privacy Policy section -->
      <div sprkStackItem id="federal-privacy-notice">
        <h1 class="sprk-u-mbm">Federal Privacy Notice</h1>
            <p>
              Some companies within the Rocket Family of Companies are required to provide an additional Privacy Notice under the Gramm-Leach-Bliley Act. 
              For convenience, these notices are provided below.
            </p>
            <p>
              <a href="https://static.rocketloans.com/disclosures/our-privacy-notice.pdf" target="blank">Rocket Loans</a>
              <br>
              <a href="https://www.rocketmoney.com/privacy-notice" target="blank">Rocket Money</a>
              <br>
              <a href="/assets/pdf/ServicingPrivacyPolicyV2.pdf" target="blank">Rocket Mortgage</a>
              <br>
              <a href="https://www.rocketcard.com/privacy-notice" target="blank">Rocket Card</a>
            </p>  
        <a class="top-link margin--bottom" href="#/privacy-policy#top">Back to Top</a>
      </div>
    </div>
    <!-- 2/5 stack w/table of contents -->
    <div sprkStackItem class="sprk-o-Stack__item--two-fifths@m sprk-o-Stack sprk-o-Stack--end-column@m c-SideBar">
      <!-- print button -->
      <div class="print-button--container print-button--wide">
        <button sprkButton variant="secondary" id="print-button" (click)="printPrivacyPolicy()">Print Privacy Policy</button>
      </div>
      <!-- table of contents -->
      <sprk-card idString="default" class="table-of-contents--wide">
        <sprk-stack sprkCardHeader sprkStackItem itemSpacing="medium">
          <h3 class="table-of-contents--header sprk-b-TypeDisplayFive sprk-o-Stack__item c-SideBar__header-copy">
            Table of Contents
          </h3>
        </sprk-stack>
        <sprk-stack sprkCardContent sprkStackItem itemSpacing="medium" class="sprk-u-BackgroundColor--white">
          <div sprkStackItem sprkText variant="bodyTwo" class="table-of-contents--contents">
            <a href="#/privacy-policy#who-we-are">Who We Are</a>
            <a href="#/privacy-policy#information-we-collect">Information We Collect</a>
            <a href="#/privacy-policy#how-we-use">How We Use Your Information</a>
            <a href="#/privacy-policy#how-we-share">How We Share Your Information</a>
            <a href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a>
            <a href="#/privacy-policy#childrens-privacy">Children's Privacy</a>
            <a href="#/privacy-policy#california-shine-the-light">California Shine The Light</a>
            <a href="#/privacy-policy#processing-of-information">Processing Of Information In The United States and Other Countries</a>
            <a href="#/privacy-policy#your-choices">Your Choices</a>
            <a href="#/privacy-policy#changes-to-this-policy">Changes To This Policy</a>
            <a href="#/privacy-policy#contact-information">Contact Information</a>
            <a href="#/privacy-policy#additional-information" class="link--purple">Additional Information Regarding Your State Privacy Rights</a>
            <a href="#/privacy-policy#federal-privacy-notice">Federal Privacy Notice</a>
          </div>
        </sprk-stack>
      </sprk-card>
      <!-- nearly identical table of contents for smaller viewports: accordion at bottom of page -->
      <sprk-accordion class="table-of-contents--accordion table-of-contents--narrow" *ngIf="showTableOfContents">
        <sprk-accordion-item
          heading="Table of Contents"
          idString="table-of-contents-narrow"
          isActive="false"
          >
          <div sprkStackItem sprkText variant="bodyTwo" class="table-of-contents--contents table-of-contents--list">
            <a href="#/privacy-policy#who-we-are">Who We Are</a>
            <a href="#/privacy-policy#information-we-collect">Information We Collect</a>
            <a href="#/privacy-policy#how-we-use">How We Use Your Information</a>
            <a href="#/privacy-policy#how-we-share">How We Share Your Information</a>
            <a href="#/privacy-policy#advertising-and-analytics">Advertising and Analytics</a>
            <a href="#/privacy-policy#childrens-privacy">Children's Privacy</a>
            <a href="#/privacy-policy#california-shine-the-light">California Shine The Light</a>
            <a href="#/privacy-policy#processing-of-information">Processing Of Information In The United States and Other Countries</a>
            <a href="#/privacy-policy#your-choices">Your Choices</a>
            <a href="#/privacy-policy#changes-to-this-policy">Changes To This Policy</a>
            <a href="#/privacy-policy#contact-information">Contact Information</a>
            <a href="#/privacy-policy#additional-information" class="link--purple">Additional Information Regarding Your State Privacy Rights</a>
            <a href="#/privacy-policy#federal-privacy-notice">Federal Privacy Notice</a>
          </div>
        </sprk-accordion-item>
      </sprk-accordion>
    </div>
  </div>
</div>
