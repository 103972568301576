import { Component, ElementRef, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'sprk-icon-set',
  template: `<div></div>`
})
export class SprkIconSetComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID)
    public readonly platformId: object,
    public ref: ElementRef) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window
        .fetch('https://www.rockomni.com/mcds/assets/GlobalContent/NonStockImages/Icons/spark-core-icons-V10.svg', {})
        .then(response => response.text())
        .then(svg => {
          this.ref.nativeElement.innerHTML += svg;
        });
    }
  }
}
