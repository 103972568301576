import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { AppHookService } from 'app/services/app-hook.service';
import { SplitService } from 'app/modules/feature-flags/services/split.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements AfterViewInit {

  @Output() closed = new EventEmitter<any>();

  termsOfUseFlagIsActive: boolean;

  constructor(
    private _location: Location,
    private hooks: AppHookService,
    private splitService: SplitService
  ) {

  }

  async ngOnInit() {
    await this.getTreatments();
  }

  ngAfterViewInit() {
  }

  close() {
    this.closed.emit(true);
  }

  async getTreatments() {
    this.termsOfUseFlagIsActive = await this.splitService.getTreatmentFor("tos-update");
  }

  goBack() {
    this._location.back();
  }

  get isWebView() {
    return this.hooks.isWebView;
  }
}
