import { Injectable } from '@angular/core';
import { readCookie } from 'app/consumer/util/cookie';
import * as deepmerge from 'deepmerge';
 
export const metricIdentifiers = 'metricIdentifiers';

@Injectable()
export class MetricsService {

  metrics: {} | { metricIdentifiers: string } = {};

  constructor() { 
    this.metrics[metricIdentifiers] = {};

    // read the adobe visitor ID from the cookie
    let adobeVisitorId = readCookie("s_ecid");
    if (adobeVisitorId != null) {
      adobeVisitorId = decodeURIComponent(adobeVisitorId).split("|").slice(-1)[0];
      this.metrics[metricIdentifiers].adobeVisitorId = adobeVisitorId;
    }

    // read the Rocket visitor ID from the cookie
    try {
      let rvid = readCookie('rvid');
      if (rvid != null) {
        this.metrics[metricIdentifiers].rvid = atob(decodeURIComponent(rvid));
      }
    } catch {
      console.log("Error retrieving RVID cookie.")
    }
  }

  addMetricIdentifier(newMetricIdentifier) {
    let newMetrics: {} | { metricIdentifiers: string } = deepmerge( newMetricIdentifier, this.metrics["metricIdentifiers"]);
    this.metrics["metricIdentifiers"] = newMetrics;
  }
}
