<div *ngIf="input" class="form-group" [class.hasError]="input.errors && input.touched" [attr.id]="input.name + 'Field'">
    <div class="rcktInput">
        <ng-content select="rckt-help-link"></ng-content>
        <label *ngIf="!required" [ngClass]="inputStatus" [for]="for">{{ label }}</label>
        <label *ngIf="required" [ngClass]="inputStatus" for="testy">{{ label }}<span class="requiredField">*</span></label>
        <ng-content select="[formControlName]"></ng-content>
        <app-mask *ngIf="maskToggle" (toggled)="toggleType($event)" label="{{ label }}"></app-mask>
    </div>
    <ng-content select="[helpText]"></ng-content>
    <div class="errors" *ngIf="input.errors && input.touched">
        <ng-content select="rckt-error"></ng-content>
    </div>
</div>
